import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Cookie from './ulti/tools'
import element from 'element-ui'
Vue.use(element)
Vue.config.productionTip = false;
Vue.prototype.Cookie = Cookie.Cookie
new Vue({
  router,
  store,
  render: function (h) { return h(App) }
}).$mount('#app')
