<template>
    <a :href="`#/learn?id=${v.id}`"  class="item">
      <div class="img" style="background-size: 100% 100%" :style="{ backgroundImage: `url('${v.imagesmall}')`,}"></div>
      <p class="title ellipsis2">{{ v.name }}</p>
      <p class="difficulty">{{ v.level }} · {{ v.learningnumber }}人报名</p>
      <div class="bottom clearfix">
        <span class="price l red bold">￥{{ v.price }}</span>
      </div>
    </a>
</template>

<script>
export default {
    props:['v']
};
</script>

<style>

.container-types.must-learn .list .item,
.container-types.new-course .list .item {
  position: relative;
  width: 270px;
  height: 272px;
  margin: 0 24px 24px 0;
  background: #fff;
  box-shadow: 0 6px 10px 0 rgba(95, 101, 105, 0.15);
  border-radius: 8px;
  float: left;
  transition: all 0.2s;
}

.container-types.must-learn .list .item:nth-child(4n),
.container-types.new-course .list .item:nth-child(4n) {
  margin-right: 0;
}

.container-types.must-learn .list .item:nth-last-child(-n + 4),
.container-types.new-course .list .item:nth-last-child(-n + 4) {
  /* margin-bottom: 0; */
}

.container-types.must-learn .list .item:hover,
.container-types.new-course .list .item:hover {
  transform: translateY(-2px);
  box-shadow: 0 12px 20px 0 rgba(95, 101, 105, 0.15);
}

.container-types.must-learn .list .item .img,
.container-types.new-course .list .item .img {
  height: 152px;
  margin-bottom: 8px;
  border-radius: 8px 8px 0 0;
}

.container-types.must-learn .list .item .title,
.container-types.new-course .list .item .title {
  color: #545c63;
  line-height: 20px;
  height: 40px;
  margin-bottom: 8px;
  padding: 0 8px;
}

.container-types.must-learn .list .item .title.ellipsis2,
.container-types.new-course .list .item .title.ellipsis2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.container-types.must-learn .list .item .difficulty,
.container-types.new-course .list .item .difficulty {
  display: block;
  color: #9199a1;
  font-size: 12px;
  margin-bottom: 8px;
  padding: 0 8px;
}

.container-types.must-learn .list .item .bottom,
.container-types.new-course .list .item .bottom {
  font-size: 12px;
  color: #9199a1;
  line-height: 18px;
  padding: 0 8px;
  margin-bottom: 18px;
}

.container-types.must-learn .list .item .bottom .red,
.container-types.new-course .list .item .bottom .red {
  color: #f01414;
}

.container-types.must-learn .list .item .bottom .delete-line,
.container-types.new-course .list .item .bottom .delete-line {
  text-decoration: line-through;
}

.container-types.must-learn .list .item .bottom .discount,
.container-types.new-course .list .item .bottom .discount {
  border: 1px solid rgba(242, 13, 13, 0.2);
  border-radius: 2px;
  font-size: 12px;
  line-height: 1;
  margin-right: 4px;
  overflow: hidden;
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.container-types.must-learn .list .item .bottom .discount i,
.container-types.new-course .list .item .bottom .discount i {
  font-style: normal;
  padding: 3px 4px;
}

.container-types.must-learn .list .item .bottom .discount i.name,
.container-types.new-course .list .item .bottom .discount i.name {
  color: #fff;
  background-color: rgba(242, 13, 13, 0.6);
}

.container-types.must-learn .list .item .bottom .discount i.countdown,
.container-types.new-course .list .item .bottom .discount i.countdown {
  font-family: DINCondensed, "微软雅黑";
  color: #f76e6e;
  display: flex;
  padding-top: 4px;
  padding-bottom: 2px;
}

.container-types.must-learn .list .item .bottom .discount i.countdown .day,
.container-types.new-course .list .item .bottom .discount i.countdown .day {
  display: inline-block;
  width: 9px;
  height: 12px;
  /*background: url(/static/img/course/v4/day.png) no-repeat top/100% auto*/
}

.container-types.must-learn .list .item .bottom .origin-price,
.container-types.new-course .list .item .bottom .origin-price {
  color: #6d7278;
  line-height: 20px;
  margin-left: 4px;
}

.container-types.must-learn .list .item .bottom .price,
.container-types.new-course .list .item .bottom .price {
  line-height: 20px;
  margin-right: 2px;
}

</style>
