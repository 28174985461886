<template>
  <div>
    <div class="topHeader">
      <div class="page-container clearfix" id="nav" style="background-color: #fff !important">
        <div class="index-nav clearfix">
          <div id="logo" class="logo"><a href="/" target="_self" title="首页"><img title="p8课堂"  src="../../assets/logo.png" style="margin-left: 4px;margin-top: 6px;width: 150px;height: 55px"/></a></div>
          <ul class="nav-item l">
            <li><a href="#/" target="_self">首页</a></li>
            <li><a href="#/gratis" target="_self">实战课</a></li>
            <li><a href="#/fine" target="_self">体系课</a></li>
          </ul>
          <div v-show="!cache.islogin" id="login-area">
            <ul class="header-unlogin clearfix">
              <li class="header-favorites">
                <p>收藏网站</p>
                <div class="favorites-tips"><span class="imv2-star-revert"></span>使用<b> Ctrl+D </b>可将网站添加到书签</div>
              </li>
              <li class="shop-cart" id="shop-cart">
                <a href="#/cart" class="shop-cart-icon" target="_blank">
                  <span class="icon-shopping-cart js-endcart"></span>
                  <span class="shopping_icon js-cart-num" data-ordernum="0" data-cartnum="0" style="display: none">0</span>
                </a>
              </li>
              <li class="header-signin">
                <a href="javascript:void(0)" id="js-signin-btn" @click="flg(1)">登录</a>/<a href="javascript:void(0)" id="js-signup-btn" @click="flg(2)">注册</a>
              </li>
            </ul>
          </div>
          <div v-show="cache.islogin" id="login-area">
            <ul class="clearfix logined">
              <li class="header-favorites">
                <p>收藏网站</p>
                <div class="favorites-tips"><span class="imv2-star-revert"></span>使用<b> Ctrl+D </b>可将网站添加到书签</div>
              </li>
              <li class="shop-cart" id="shop-cart">
                <a href="#/cart" class="shop-cart-icon" target="_blank">
                  <span class="icon-shopping-cart js-endcart"></span>
                  <span class="shopping_icon js-cart-num" data-ordernum="0" data-cartnum="3" style="">{{cache.user.cartcount}}</span>
                </a>
                <div class="my-cart" id="js-my-cart"></div>
              </li>
              <li class="remind_warp">
                <i class="msg_remind" style="display: inline"></i>
                <a target="_blank" href="/"><i class="icon-notifi"></i></a>
              </li>
              <li class="user-mycourse-box">
                <a class="user-card-item js-header-courseurl" href="#/grzx" target="_self"><span>我的课程</span></a>
              </li>
              <li class="set_btn user-card-box" :class="{ hover: hovePages }" id="header-user-card">
                <a id="header-avator" :class="{ hover: hovers }" class="user-card-item js-header-avator" href="#/grzx" @mousemove="(hovers = true), (hovePages = true)">
                  <img width="40" height="40" :src="cache.user.avatar" />
                </a>
                <div @mouseleave.self="hovePages = false" class="g-user-card">
                  <div class="card-inner">
                    <div class="card-top clearfix">
                      <a class="l" href="https://www.imooc.com/u/index/allcourses"><img :src="cache.user.avatar"/></a>
                      <div class="card-top-right-box l">
                        <a href="https://www.imooc.com/u/index/allcourses"><span class="name text-ellipsis">{{cache.user.username}}</span></a>
                        <div class="meta">
                          <a href="#/u/index/experience">经验<b id="js-user-mp">{{ cache.user.experience }}</b></a>
                          <a href="#/u/index/credit">积分<b id="js-user-credit">{{cache.user.score}}</b></a>
                        </div>
                      </div>
                    </div>
                    <div class="user-center-box">
                      <ul class="clearfix">
                        <li class="l"><a href="#/grzx" ><span class="user-center-icon icon-tick"></span>我的课程</a></li>
                        <li class="l"><a href="#/myoder" ><span class="user-center-icon icon-receipt"></span>订单中心</a></li>
                        <li class="l"><a href="#/grxx" ><span class="user-center-icon icon-set"></span>个人设置</a></li>
                      </ul>
                    </div>
                    <!-- <div class="card-history">
                      <span class="history-item">
                        <span class="tit text-ellipsis"
                          >SpringCloud Finchley三版本微服务实战</span
                        >
                        <span class="media-name text-ellipsis"
                          >1-1 SpringCloud导学</span
                        >
                        <i class="icon-clock"></i>
                        <a
                          href="//coding.imooc.com/lesson/187.html#mid=24123"
                          class="continue"
                          >继续</a
                        >
                      </span>
                    </div> -->
                    <div class="card-sets clearfix"><a href="javascript:void(0) " @click="layout" class="l">安全退出</a></div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          <div class="search-warp clearfix" style="min-width: 32px; height: 72px">
            <div class="search-area" data-search="top-banner">
              <input class="search-input" data-suggest-trigger="suggest-trigger" placeholder="请输入关键字..." type="text" autocomplete="off" v-model="searchValue"/>
              <div class="search-area-result nav-search-box js-search-wrap">
                <div class="hot">
                  <h2>热搜</h2>
                  <div class="hot-box">
                    <a href="javascript:void(0)" class="hot-item js-history-search">Java工程师</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">Vue</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">Python</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">Go</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">SpringBoot</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">Docker</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">React</a>
                    <a href="javascript:void(0)" class="hot-item js-history-search">小程序</a>
                  </div>
                </div>
                <div class="history"><ul data-suggest-result="suggest-result"></ul></div>
              </div>
            </div>
            <div class="showhide-search" data-show="no">
              <i @click="search" class="imv2-search2"></i>
            </div>
          </div>
        </div>
      </div>
    </div>
    <login @tables="tables" @close="close" v-show="login" />
    <regit @tables="tables" @close="close" v-show="regit" />
  </div>
</template>
<script>
import login from "./login.vue";
import regit from "./regit.vue";
import { mapState } from "vuex";
import {getUser, setUser, removeToken, setToken,getCar,addCar} from "../../ulti/auth";
import api from "../../ulti/ajax";


export default {
  data() {
    return {
      login: false,
      regit: false,
      hovers: false,
      hovePages: false,
      searchValue:""
    };
  },

  computed: {
    ...mapState(["cache"]),
  },

  async mounted() {
    //console.log("1111"+JSON.stringify(this.cache ));

    await api.getInfo().then(res2 => {
     // console.log("222222222res2.data.user2222"+JSON.stringify(res2))
      //setUser(JSON.stringify(res2.data.user));
      this.$store.dispatch('setUser',{
        id:res2.data.user.id,
        username:res2.data.user.username,
        usertype:res2.data.user.usertype,
        sex:res2.data.user.sex,
        customerid:res2.data.user.customerid,
        avatar:res2.data.user.avatar,
        score:res2.data.user.score,
        experience:res2.data.user.experience,
        cartcount:res2.data.user.cartcount,
      });
      //console.log("3333333333333333333.data.user2222"+JSON.stringify(res2))
    });
   // console.log("2222"+JSON.stringify(this.cache ));

  },

  methods: {
    closeHover(e) {
      console.log(e.target);
    },
    flg(a) {
      if (a == 1) {
        this.login = true;
        this.regit = false;
      } else {
        this.login = false;
        this.regit = true;
      }
    },
    search(){
      window.location.href = '/search?searchName='+this.searchValue
    },
    tables() {
      if (this.login) {
        this.login = false;
        this.regit = true;
      } else {
        this.login = true;
        this.regit = false;
      }
    },
    close() {
      this.regit = false;
      this.login = false;
    },
    async layout() {
     // console.log("111"+JSON.stringify(11))
      await  api.logout().then(res => {
        console.log(JSON.stringify(res))
        if (res.data.code == 200) {
         // console.log("2222222222222"+JSON.stringify(res))
          //setUser("");
         // removeToken();

          this.$store.dispatch("lyaout");
          window.location.reload();

        }else{
          alert("操作失败："+res.data.msg);
          //this.getCode();
        }
      });
    },
    // 结算
    js() {
      // 结算
      // api.
    },
  },
  components: {
    login,
    regit,
  },
};
</script>

<style>
#main {
  padding: 20px 0;
  min-height: calc(100vh - 206px);
}
.waper {
  width: 1152px;
  margin: 0 auto;
}

.topHeader {
  background: #07111b;
  border-bottom: 1px solid #f3f5f6;
}

.topHeader .page-container {
  width: auto;
  z-index: 900;
  position: relative;
}

.topHeader .page-container .index-nav {
  max-width: 1366px;
  min-width: 1152px;
  margin: 0 auto;
}

.topHeader .page-container.double202011 {
  /*background: url(/static/img/index/header-1111.png) center center no-repeat*/
  background-size: 100% 100%;
}

.topHeader .page-container.double202011 .nav-item li a {
  color: #fff !important;
}

.topHeader .page-container.double202011 .nav-item li a:hover {
  color: #ffe294 !important;
}

.topHeader .page-container.double202011 #login-area .app-download > a {
  color: #fff !important;
}

.topHeader .page-container.double202011 #login-area .app-download > a:hover {
  color: #ffe294 !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .shop-cart:hover
  .shop-cart-icon {
  background: #fff !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .shop-cart:hover
  .shop-cart-icon
  span {
  color: #f01414 !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .shop-cart.hover
  .shop-cart-icon
  .shopping_icon {
  color: #fff !important;
}

.topHeader .page-container.double202011 #login-area .shop-cart .shop-cart-icon {
  border: 1px solid rgba(255, 255, 255, 0.5) !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .shop-cart
  .shop-cart-icon
  span {
  color: #fff !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .header-unlogin
  .header-signin
  a {
  color: #fff !important;
}

.topHeader
  .page-container.double202011
  #login-area
  .header-unlogin
  .header-signin
  a:hover {
  color: #ffe294 !important;
}

.topHeader .page-container.double202011 #login-area .logined > li > a {
  color: #fff !important;
}

.topHeader .page-container.double202011 #login-area .logined > li > a:hover {
  color: #ffe294 !important;
}

.topHeader .page-container.double202011 #login-area .logined > li > a:hover i {
  color: #ffe294 !important;
}

.topHeader .logo {
  float: left;
  margin: 0 20px;
}

.topHeader .logo a {
  display: block;
  width: 140px;
  height: 71px;
  /*background: url(/static/img/common/logo.png?t=2.4) center center no-repeat*/
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.topHeader .nav-item li {
  float: left;
}

.topHeader .nav-item li a {
  padding: 0 16px;
  display: block;
  color: #545c63;
  text-align: center;
  font-size: 16px;
  height: 71px;
  line-height: 71px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  position: relative;
}

.topHeader .nav-item li a .icn-new {
  position: absolute;
  display: inline-block;
  top: 18px;
  width: 14px;
  height: 10px;
  /*background: url(/static/img/common/new2.png) no-repeat 0 0;*/
  background-size: 100%;
  background-position: center;
}

.topHeader .nav-item li a.active,
.topHeader .nav-item li a:hover {
  color: #fff;
}

.topHeader .nav-item li a.gold {
  color: #dba338 !important;
}

.topHeader .nav-item li a.gold.active,
.topHeader .nav-item li a.gold:hover {
  color: #f20d0d !important;
}

.topHeader #login-area {
  float: right;
  position: relative;
}

.topHeader #login-area .dist-money {
  line-height: 71px;
  position: relative;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.topHeader #login-area .dist-money:hover > a {
  color: #fff;
}

.topHeader #login-area .dist-money > a {
  color: rgba(255, 255, 255, 0.6);
  padding: 0 12px;
}

.topHeader #login-area .dist-money a {
  width: 122px !important;
  padding: 0 12px;
  box-sizing: border-box;
}

.topHeader #login-area .user-mycourse-box > a {
  text-align: center;
  color: #787d82;
}

.topHeader #login-area .header-favorites {
  position: relative;
  padding: 0 15px;
}

.topHeader #login-area .header-favorites p {
  color: #545c63;
  cursor: pointer;
  font-size: 16px;
  line-height: 71px;
}

.topHeader #login-area .header-favorites p:hover {
  color: #f01414;
}

.topHeader #login-area .header-favorites .favorites-tips {
  display: none;
  position: absolute;
  top: 73px;
  left: 50%;
  width: 240px;
  padding: 12px 20px;
  margin-left: -140px;
  color: #545c63;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  transition: all 0.2s;
  font-size: 12px;
  text-align: center;
  line-height: 1;
}

.topHeader #login-area .header-favorites .favorites-tips b {
  font-size: 16px;
  color: #f01414;
  font-weight: 500;
}

.topHeader #login-area .header-favorites:hover .favorites-tips {
  display: block;
}

.topHeader #login-area .app-download {
  line-height: 71px;
  color: rgba(255, 255, 255, 0.6);
}

.topHeader #login-area .app-download > a {
  padding: 0 15px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
}

.topHeader #login-area .app-download > a:hover {
  color: #fff;
}

.topHeader #login-area .shop-cart {
  height: 36px;
  line-height: 36px;
  margin: 18px 0;
}

.topHeader #login-area .shop-cart .shop-cart-icon {
  display: inline-block;
  padding: 0 12px;
  width: auto;
  box-sizing: border-box;
  height: 36px;
  line-height: 36px;
  color: #787d82;
  text-align: center;
}

.topHeader #login-area .shop-cart .my-cart {
  top: 55px;
}

.topHeader #login-area .remind_warp {
  width: 52px;
}

.topHeader #login-area .remind_warp a {
  width: 52px !important;
  height: 48px;
  line-height: 48px;
}

.topHeader #login-area .remind_warp .msg_remind {
  display: none;
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: rgba(240, 20, 21, 0.7);
  right: 16px;
  top: 22px;
  border-radius: 50%;
}

.topHeader #login-area .remind_warp .icon-notifi {
  display: block;
  line-height: 72px;
  font-size: 22px;
  text-align: center;
  cursor: pointer;
  -webkit-transition: color 0.2s;
  -moz-transition: color 0.2s;
  transition: color 0.2s;
}

.topHeader #login-area .remind_warp .icon-notifi:hover {
  color: #fff;
}

.topHeader #login-area .logined > li {
  float: left;
  position: relative;
}

.topHeader #login-area .logined > li.user-mycourse-box a:hover {
  color: #f01414;
}

.topHeader #login-area .logined > li > a {
  display: block;
  width: 68px;
  font-size: 16px;
  height: 72px;
  line-height: 72px;
  color: #545c63;
  text-align: center;
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.topHeader #login-area .logined > li > a:hover {
  color: #fff;
}

.topHeader #login-area .logined > li > a:hover i {
  color: #fff;
}

.topHeader #login-area .header-unlogin > li {
  float: left;
}

.topHeader #login-area .header-unlogin .header-signin {
  margin-left: 12px;
  color: rgba(255, 255, 255, 0.6);
}

.topHeader #login-area .header-unlogin .header-signin a {
  display: inline-block;
  padding: 0 12px;
  height: 71px;
  line-height: 71px;
  font-size: 14px;
  color: rgba(255, 255, 255, 0.6);
  -webkit-transition: background-color 0.2s;
  -moz-transition: background-color 0.2s;
  transition: background-color 0.2s;
}

.topHeader #login-area .header-unlogin .header-signin a:hover {
  color: rgba(240, 20, 20, 0.8);
}

.search-area {
  margin: 12px 0;
}

.search-warp {
  position: relative;
  float: left;
  margin-left: 24px;
}

.search-warp .showhide-search {
  width: 20px;
  height: 24px;
  text-align: right;
  position: absolute;
  display: inline-block;
  right: 0;
  bottom: 24px;
  padding: 0 8px;
  border-radius: 18px;
}

.search-warp .showhide-search i {
  display: block;
  height: 24px;
  color: #545c63;
  cursor: pointer;
  font-size: 18px;
  line-height: 24px;
  width: 20px;
}

.search-area {
  float: right;
  position: relative;
  height: 40px;
  padding-right: 36px;
  border-bottom: 1px solid rgba(255, 255, 255, 0.4);
  zoom: 1;
  background: #f3f5f6;
  border-radius: 4px;
  margin: 16px 0;
  width: 324px;
  box-sizing: border-box;
  font-size: 0;
  -webkit-transition: width 0.3s;
  -moz-transition: width 0.3s;
  transition: width 0.3s;
}

.search-area .search-input {
  padding: 8px 12px;
  font-size: 14px;
  color: #9199a1;
  line-height: 24px;
  height: 40px;
  width: 100%;
  float: left;
  border: 0;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
  background-color: transparent;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -ms-box-sizing: border-box;
  box-sizing: border-box;
}

.search-area .search-input.w100 {
  width: 100%;
}

.search-area .hotTags {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 32px;
}

.search-area .hotTags a {
  display: inline-block;
  padding: 4px 8px;
  height: 16px;
  font-size: 14px;
  color: #9199a1;
  line-height: 16px;
  margin-top: 8px;
  max-width: 60px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.search-area .hotTags a:hover {
  color: #f21f1f;
}

.search-area input::-webkit-input-placeholder {
  color: #a6a6a6;
}

.search-area input::-moz-placeholder {
  color: #a6a6a6;
}

.search-area input:-moz-placeholder {
  color: #a6a6a6;
}

.search-area input:-ms-input-placeholder {
  color: #a6a6a6;
}

.search-area .btn_search {
  float: left;
  cursor: pointer;
  width: 30px;
  height: 38px;
  text-align: center;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  transition: background-color 0.3s;
}

.search-area .search-area-result {
  position: absolute;
  left: 0;
  top: 57px;
  width: 300px;
  margin-bottom: 20px;
  border-top: none;
  background-color: #fff;
  box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.2);
  font-size: 12px;
  overflow: hidden;
  display: none;
  z-index: 800;
  border-bottom-right-radius: 8px;
  border-bottom-left-radius: 8px;
}

.search-area .search-area-result.hot-hide {
  top: 47px;
}

.search-area .search-area-result.hot-hide .hot {
  display: none;
}

.search-area .search-area-result.hot-hide .history {
  border-top: 0;
}




.my_message a span {
  display: none;
}
.topHeader .app-down-area a:hover {
  color: #93999f;
}
.topHeader .page-container #login-area .header-unlogin .header-signin {
  color: #d9dde1;
}

.topHeader .page-container #login-area .header-unlogin .header-signin a {
  color: #4d555d;
}

.topHeader .page-container #login-area .header-unlogin .header-signin a:hover {
  color: #f01414;
}

.topHeader .logo a {
  background: 0 0 !important;
}
.topHeader .logo a img {
  height: 72px;
}
.topHeader .nav-item li a:hover {
  color: #07111b;
}
.icon-shopping-cart:before {
  content: "\e929";
}
.shop-cart:hover .my-cart {
  display: inline-block;
}
</style>
