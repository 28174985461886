import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  // 免费列表
  {
    path: '/gratis',
    name: 'gratis',
    component: function () {
      return import('../views/gratis.vue')
    }
  },
  // 精品课程
  {
    path: '/fine',
    name: 'fine',
    component: function () {
      return import('../views/Fine.vue')
    }
  },
  // 帮助中心
  {
    path:'/help',
    name: 'help',
    component: function () {
      return import('../views/foolter/help.vue')
    }
  },
  {
    path:'/helps/*',
    name: 'helps',
    component: function () {
      return import('../views/foolter/helps.vue')
    }
  },
  // contact 联系我们
  {
    path:'/contact',
    name: 'contact',
    component: function () {
      return import('../views/foolter/contact.vue')
    }
  },
  {
    path:'/cart',
    name:"cart",
    component:()=>import('../views/my/cart.vue')
  },
  // 确认订单界面
  {
    path:'/cartOder',
    name:"cart",
    component:()=>import('../views/my/cardDd.vue')
  },
  // 订单支付界面
  {
    path:'/dd',
    name:"cart",
    component:()=>import('../views/my/dd.vue')
  },
  // 搜索界面
  {
    path:'/search',
    name:"search",
    component:()=>import('../views/search.vue')
  },
  // 信息反馈界面
  {
    path:"/feedback",
    name:"feedback",
    component:()=>import('../views/foolter/write.vue')
  },
    // 信息反馈界面
  {
    path:"/learn",
    name:"learn",
    component:()=>import('../views/curriculum/learn.vue')
  },
  {
    path:"/sale",
    name:"sale",
    component:()=>import("../views/curriculum/jingpin.vue")
  },
  // fineVideo
  {
    path:"/fineVideo",
    name:"fineVideo",
    component:()=>import("../views/curriculum/fineVideo.vue")
  },
  {
    path:"/excellent",
    name:"Excellent",
    component:()=>import("../views/curriculum/learlist.vue")
  },
  {
    path:"/information",
    name:"information",
    component:()=>import("../views/information/index.vue")
  },
  {
    path:"/myoder",
    name:"oder",
    component:()=>import("../views/information/oder.vue")
  },
  {
    path: '/grxx',
    name: 'About',
    component: function () {
      return import('../views/person/grxx.vue')
    }
  },
  {
    path: '/grzx',
    name: 'About',
    component: function () {
      return import('../views/person/grzx.vue')
    }
  },
  {
    path: '/video',
    name: 'video',
    component: function () {
      return import('../views/video/index.vue')
    }
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
