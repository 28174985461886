import axios from 'axios'
import { getToken } from '@/ulti/auth'
let api = axios.create({
  //baseURL: "http://127.0.0.1:4523/mock/617418",
  //baseURL: "http://localhost/dev-api",//本地开发
  baseURL: "/api",//生产环境
  // 超时
  timeout: 10000
})


// request拦截器
api.interceptors.request.use(config => {
  // 是否需要设置 token
  const isToken = (config.headers || {}).isToken === false
  if (getToken() && !isToken) {
    config.headers['Authorization'] = 'Bearer ' + getToken() // 让每个请求携带自定义token 请根据实际情况自行修改
  }
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  return config
}, error => {
  console.log(error)
  Promise.reject(error)
})
// http://121.201.66.25:9000
export default {

  // 首页列表数据
  getIndexList() {
    return api.request({
      url: '/prod/web/getCoursetypeList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  getBannerList() {
    return api.request({
      url: '/adm/web/bannerList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },


// http://localhost/dev-api/prod/course/courseList?pageNum=1&pageSize=10&addtime_begin=&addtime_end=&editdate_begin=&editdate_end=
  // 首页列表数据  api.getCourseListPage(typeid1,typeid2,pageNum,pageSize,search).then(res => {
  getCourseListPage(typeid1,typeid2,pageNum,pageSize,search) {
    return api.request({
      url: '/prod/web/getCourseListPage?typeid1='+typeid1+"&typeid2"+typeid2+"&pageNum"+pageNum+"&pageSize"+pageSize+"&search"+search,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },


  // 首页列表数据
  getIndexAllData() {
    return api.request({
      url: '/prod/web/getCourseList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  // 获取文章详情
  //http://localhost:8080/swagger-ui/index.html?urls.primaryName=p8classpath-prod#/
  getcoursedetail(courseid) {
    return api.request({
      url: '/prod/web/getcoursedetail?courseid='+courseid,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
    //return api.get('/prod/web/getCourseList')
  },


  // 获取问答
  getQuestionList(courseid) {
    return api.request({
      url: '/cus/web/getQuestionList?courseid='+courseid,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
    //return api.get('/prod/web/getCourseList')
  },

  getCommentByCourseId(courseid) {
    return api.request({
      url: '/cus/web/getCommentList?courseid='+courseid,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  // 获取帮助类型列表
  getHelptypeList() {
    return api.request({
      url: '/adm/web/getHelptypeList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })

  },

  // 获取帮助
  getHelpList(helptypeid) {
    return api.request({
      url: '/adm/web/getHelpList?helptpeid='+helptypeid,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })

  },

  // 获取热门帮助
  getHelpListHots() {
    return api.request({
      url: '/adm/web/getHelpListHots',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },



  // 获取订单列表
  getOrderList(query) {
    return api.request({
      url: '/ord/web/getOrderList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  getShopcartList() {
    return api.request({
      url: '/ord/web/getShopcartList',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },
  getOrderConfirmList(ids,type) {
    return api.request({
      url: '/ord/web/getOrderConfirmList?ids='+ids+"&type="+type,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },
  getOrderConfirmListToken(ids,type) {
    return api.request({
      url: '/ord/web/getOrderConfirmListToken?ids='+ids+"&type="+type,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },
  commitOrder(ids,type,token) {
    return api.request({
      url: '/ord/web/commitOrder?ids='+ids+"&type="+type+"&token="+token,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  getOrderStatus(keyid) {
    return api.request({
      url: '/ord/web/getOrderStatus?keyid='+keyid,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },



  getOrder(id) {
    return api.request({
      url: '/ord/web/getOrder?id='+id,
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  getMyCourse() {
    return api.request({
      url: '/cus/web/getMyCourse',
      headers: {
        isToken: true
      },
      method: 'get',
      timeout: 20000
    })
  },

  buy(uerId, cartId) {
    // api.post(,{userId,cartId})
    return (new Promise((req, res) => {
      req({
        message: "购买成功",
        severId: 12352023546
      })
    }))
  },


  AddCart(courseid) {
    return api.request({
      url: '/ord/web/shopcartAdd?courseid='+courseid,
      headers: {
        isToken: true
      },
      method: 'post',
      timeout: 20000
    })
  },



  // 获取验证码
  getOder() {
    return (new Promise((req, res) => {
      req({
        messge: "发送成功",
      })
    }))
  },
  // getgrzxData
  getgrzxData() {
    return (new Promise((req, res) => {
      req({
        data: [
          {
            // 课程图片
            image:
                "http://img1.sycdn.imooc.com/616f7dfb09b3edf705400304-360-202.png",
            // 课程标题
            title: "写出一份完美的PRD文档",
            // 课程类型
            type: "免费课",
            // 已学习
            yx: "0",
            // 代码
            dm: "0",
            // 问答
            wd: "0",
            // 学习至
            xxz: "1.10 课程引言",
            // 课程id
            courseid: 10,
            // 用时
            ys: "100",
          },
        ]
      })
    }))
  },
  // cancelCourseid 取消课程收藏
  cancelCourseid(Courseid) {
    //return api.post('',Courseid)
    return (new Promise((req, res) => {
      req({
        messge: "取消收藏成功",
      })
    }))
  },
  // cancellationOder 取消订单
  cancellationOder(codeId) {
    //return api.post('',codeId)
    return (new Promise((req, res) => {
      req({
        messge: "取消成功",
      })
    }))
  },
  // 获取精品课程列表
  getLearnserve(Courseid) {
    // return api.get()
    return (new Promise((req, res) => {
      req({
        messge: "取消成功",
        data: {
          title: "玩转自动化运维全流程",
          content: "脚本开发+工具使用+运维流程，从容应对自动化运维岗",
          prire: "200.00",
          unprire: "2800.00",
          nd: "初级",
          date: "24",
          unm: "49",
          pf: "5.00",
          // 课程安排
          nrap: [
            {
              title: "阶段1: 前端入门教程",
              // 总时长
              date: "20",
              // 课程
              kc: "27",
              listItem: [
                {
                  title: "HTML5基础语法与标签",
                  content:
                      "本周是入门前端的第一步，学会IDE插件安装和使用，掌握H5语法和基础标签的应用，学完本周内容可以进行简单的页面结构搭建。",
                  list: [
                    "123了解前端行业发展",
                    "1324了解前端行业发展",
                    "12312了解前端行业发展",
                  ],
                },
                {
                  title: "HTML5基础语法与标签",
                  content:
                      "本周是入门前端的第一步，学会IDE插件安装和使用，掌握H5语法和基础标签的应用，学完本周内容可以进行简单的页面结构搭建。",
                  list: [
                    "123了解前端行业发展",
                    "1324了解前端行业发展",
                    "12312了解前端行业发展",
                  ],
                },
              ],
            },
            {
              title: "阶段2: 前端入门教程",
              // 总时长
              date: "20",
              // 课程
              kc: "27",
              listItem: [
                {
                  title: "HTML5基础语法与标签",
                  content:
                      "本周是入门前端的第一步，学会IDE插件安装和使用，掌握H5语法和基础标签的应用，学完本周内容可以进行简单的页面结构搭建。",
                  list: [
                    "123了解前端行业发展",
                    "1324了解前端行业发展",
                    "12312了解前端行业发展",
                  ],
                },
                {
                  title: "HTML5基础语法与标签",
                  content:
                      "本周是入门前端的第一步，学会IDE插件安装和使用，掌握H5语法和基础标签的应用，学完本周内容可以进行简单的页面结构搭建。",
                  list: [
                    "123了解前端行业发展",
                    "1324了解前端行业发展",
                    "12312了解前端行业发展",
                  ],
                },
              ],
            },
          ],
          oder: {
            userList: [
              {
                img: "https://ts1.cn.mm.bing.net/th?id=OIP-C.ZeQ5h5qmFJdYmGKtrR-I9gAAAA&w=99&h=100&c=8&rs=1&qlt=90&o=6&dpr=1.25&pid=3.1&rm=2",
                username: "用防护门",
                content:
                    "我在来p8课堂学习之前也想过去培训班，但是太贵木有那么多钱~~ 后来选择了p8课堂，我觉得这个价格真是做慈善了呀。课程内容很丰富，老师讲解的也清晰。而且能够看出来p8课堂的老师们很用心，准备了资料，有的时候不懂的地方看看资料立马就明白了。课程确实会有比较难的地方，但是我也知道不是所有的知识讲一遍就能学会的，所以就自己多下功夫呗，有的时候会帮助其他同学解决问题挺有成就的哈哈~~所以也在这里鼓励遇到难点就退缩的小伙伴，要迎难而上啊",
                pageList: ["通俗易懂", "通俗易懂", "通俗易懂"],
              },
              {
                img: "https://ts1.cn.mm.bing.net/th?id=OIP-C.ZeQ5h5qmFJdYmGKtrR-I9gAAAA&w=99&h=100&c=8&rs=1&qlt=90&o=6&dpr=1.25&pid=3.1&rm=2",
                username: "用防护门",
                content:
                    "我在来p8课堂学习之前也想过去培训班，但是太贵木有那么多钱~~ 后来选择了p8课堂，我觉得这个价格真是做慈善了呀。课程内容很丰富，老师讲解的也清晰。而且能够看出来p8课堂的老师们很用心，准备了资料，有的时候不懂的地方看看资料立马就明白了。课程确实会有比较难的地方，但是我也知道不是所有的知识讲一遍就能学会的，所以就自己多下功夫呗，有的时候会帮助其他同学解决问题挺有成就的哈哈~~所以也在这里鼓励遇到难点就退缩的小伙伴，要迎难而上啊",
                pageList: ["通俗易懂", "通俗易懂", "通俗易懂"],
              },
            ],
            total: 20,
          },
          kc: `<h1>测试</h1>`
        }
      })
    }))
  },


  search(SeachValue) {
    return (new Promise((req, res) => {
      req({
        host: {
          host: ["java工程师", "Vue0", "Python", "Go"],
          relevant: ["python", "react", "小程序"],
        },
        data: [
          {
            image: "http://img1.sycdn.imooc.com/60b5dea10001987104800360.jpg",
            title: "前端工程是",
            content:
                "前端工程师是目前非常受欢迎的一个职业，生活中Web端页面和移动端的界面、微信中的小程序，以及pad等各种移动设备所看到的网页，都是由前端工程师做的。作为用户交互必不可少的环节，对前端工程师的需求",
            date: "1",
            is: false,
            cartId: 150,
            href: '/learn?id=1'
          },
          {
            image: "http://img1.sycdn.imooc.com/60b5dea10001987104800360.jpg",
            title: "前端工程是",
            content:
                "前端工程师是目前非常受欢迎的一个职业，生活中Web端页面和移动端的界面、微信中的小程序，以及pad等各种移动设备所看到的网页，都是由前端工程师做的。作为用户交互必不可少的环节，对前端工程师的需求",
            date: "2",
            is: false,
            cartId: 15,
            href: '/learn?id=1'

          },
          {
            image: "http://img1.sycdn.imooc.com/60b5dea10001987104800360.jpg",
            title: "前端工程是",
            content:
                "前端工程师是目前非常受欢迎的一个职业，生活中Web端页面和移动端的界面、微信中的小程序，以及pad等各种移动设备所看到的网页，都是由前端工程师做的。作为用户交互必不可少的环节，对前端工程师的需求",
            date: "3",
            //   是否免费
            is: true,
            cartId: 150,
            href: '/learn?id=1'

          },
        ]
      })
    }))
  },


  // 获取验证码
  getCodeImgs() {
    return api.request({
      url: '/code',
      headers: {
        isToken: false
      },
      method: 'get',
      timeout: 20000
    })
  },

  // 获取用户详细信息
  getInfo() {
    return api.request({
      url: '/adm/user/getInfo',
      headers: {
        isToken: true
      },
      method: 'get',
    })
  },


  // login 账号密码
  login(username, password,code, uuid) {
    return api.request({
      url: '/auth/loginPc',
      headers: {
        isToken: false
      },
      method: 'post',
      data: { username, password, code, uuid }
    })
    return
  },

  logout() {
    return api.request({
      url: '/auth/logoutPc',
      headers: {
        isToken: true
      },
      method: 'delete',
      timeout: 20000
    })
  },



  // 注册
  regit(phone,pass,passw) {
    return api.request({
      url: '/cus/web/regit?phone='+phone+'&pass='+pass+'&passw='+passw,
      headers: {
        isToken: false
      },
      method: 'get',
      timeout: 20000
    })
  },




// 获取验证码
  sendPone(phone) {
    return api.request({
      url: '/adm/web/sendCodeMessage?phone='+phone,
      headers: {
        isToken: false
      },
      method: 'get',
      timeout: 20000
    })
  },





}
