<template>
  <div class="modal-backdrop">
    <div id="signin" class="Rmodel in" aria-hidden="false">
      <div class="Rmodel-header">
        <h1>
          <span @click="flg = 1" :class="{ 'active-title': flg == 1 }">账号登录</span>
         <!-- <span @click="flg = 2" :class="{ 'active-title': flg == 2 }">验证码登录</span>-->
        </h1>
        <button @click="close" type="button" class="rl-close" data-dismiss="modal" hidefocus="true" aria-hidden="true"></button>
      </div>
      <div class="Rmodel-body js-loginWrap">
        <div class="clearfix">
          <form id="signup-form" autocomplete="off">
            <div class="rlf-group pr">
              <input type="text" maxlength="37"  data-validate="require-mobile-phone" autocomplete="off" class="xa-emailOrPhone ipt ipt-email js-own-name" placeholder="请输入登录手机号/邮箱" v-model="username"/>
              <p class="rlf-tip-wrap errorHint color-red" data-error-hint="请输入正确的邮箱或手机号">{{ error.user }}</p>
            </div>

            <div class="rlf-group pr" v-show="flg == 1">
              <input type="text"  oninput="if(this.value.length>2 ) this.type='password' ;else this.type='text'" class="ipt ipt-pwd js-loginPassword js-pass-pwd" placeholder="请输入密码" maxlength="20" autocomplete="off" v-model="pass"/>
              <p class="rlf-tip-wrap errorHint color-red">{{ error.pass }}</p>
              <div class="rlf-group clearfix form-control js-verify-row has-error" style="display: block; margin-bottom: 15px">
                <input type="text" name="verify" class="ipt ipt-verify l" data-validate="require-string" data-callback="checkverity" maxlength="4" data-minlength="4" placeholder="请输入验证码" v-model="code"/>
                <a href="javascript:void(0)" hidefocus="true" class="verify-img-wrap js-verify-refresh"><img class="verify-img" :src="ImgID"/></a>
                <a href="javascript:void(0)" hidefocus="true" @click="getCode" class="icon-refresh js-verify-refresh"></a>
                <p class="rlf-tip-wrap errorHint color-red" data-error-hint="请输入正确验证码" v-show="true">{{ error.code }}</p>
              </div>
            </div>


            <div class="rlf-group clearfix form-control js-verify-row" v-show="flg == 2">
              <div class="rlf-group pr phoneVerityBox">
                <input type="text" v-model="yzm" id="js-phoneVerity" data-minlength="4" class="ipt ipt-pwd" placeholder="请输入短信验证码" maxlength="4" autocomplete="off"/>
                <p class="reSend pa active js-phonecode-box"><span class="js-signin-send" @click="tabyz">{{ text }}</span></p>
                <p class="rlf-tip-wrap errorHint color-red"></p>
              </div>
            </div>
            <!--<div class="rlf-group rlf-appendix form-control clearfix">
              <label for="auto-signin" class="rlf-autoin l" hidefocus="true">
                <input type="checkbox" checked="checked" class="auto-cbx" id="auto-signin"/>7天内自动登录
              </label>
              <a class="rlf-forget r" target="_blank" hidefocus="true">无法登录</a>
              <div class="rlf-line r"></div>
              <a class="rlf-forget r" target="_blank" hidefocus="true">忘记密码</a>
            </div>-->
            <p class="rlf-tip-globle color-red" id="signin-globle-error"></p>
            <div class="rlf-group clearfix">
              <input type="button" value="登录" hidefocus="true" @click="login" class="moco-btn moco-btn-red moco-btn-lg btn-full xa-login"/>
            </div>
          </form>
        </div>
      </div>
      <div class="rl-model-footer">
        <div class="pop-login-signup-box clearfix"><span @click="tables" data-fromto="signin:signup" class="xa-showSignup">快速注册</span></div>
       <!-- <div class="pop-login-sns clearfix">
          <a href="javascript:void(0)" hidefocus="true" data-login-sns="/passport/user/tplogin?tp=weixin" class="pop-sns-weixin" data-source="账号密码"><i class="imv2-weixin11"></i></a>
          <a href="javascript:void(0)" hidefocus="true" data-login-sns="/passport/user/tplogin?tp=qq" class="pop-sns-qq" data-source="账号密码"><i class="imv2-qq11"></i></a>
          <a href="javascript:void(0)" hidefocus="true" data-login-sns="/passport/user/tplogin?tp=weibo" class="pop-sns-weibo" data-source="账号密码"><i class="imv2-weibo11"></i></a>
        </div>-->
       <!-- <div class="privacy_tip">登录即同意p8课堂<a href="https://moco.imooc.com/privacy.html" target="_blank">《隐私政策》</a></div>-->
        <div class="erweima xa-showQrcode"></div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "../../ulti/ajax";
import {setToken,getToken,setUser,getUser} from '../../ulti/auth'
export default {
  data() {
    return {
      flg: "1",
      username: "",
      pass: "",
      code:'',
      text: "获取验证码",
      yzm: "",
      error: {
        pass: "",
        user: "",
        code: "",
        yzm: "",
      },
      ImgID: "",
      uuid: "",
    };
  },
  async mounted() {
    this.getCode();
    this.getCookie()
  /*  setTimeout(() => {
      this.username='';this.pass='';this.code='';
    }, 1000);
    setTimeout(() => {
      this.username='';this.pass='';;this.code='';
    }, 1000);
    setTimeout(() => {
      this.username='';this.pass='';;this.code='';
    }, 3000);

*/
  },
  created() {},
  methods: {
    async getCode() {
      let res = await api.getCodeImgs();
      this.ImgID = "data:image/gif;base64," + res.data.img;
      this.uuid = res.data.uuid;
    },
    async login() {

      this.error.user = "";
      this.error.pass = "";
      this.error.code = "";
      this.error.yzm = "";

      if(this.flg == "1"){
        let notpass=8;
        if (this.username==undefined || this.username=='undefined' || this.username=='') {
          this.error.user = "用户名不能为空";
          notpass=1;
        }
        if (this.pass==undefined || this.pass=='undefined' || this.pass=='') {
          this.error.pass = "密码不能为空";
          notpass=1;
        }
        if (this.code==undefined || this.code=='undefined' || this.code=='') {
          this.error.code = "验证码不能为空";
          notpass=1;
        }
        if(notpass==1){
          return;
        }else{
          await  api.login(this.username, this.pass, this.code, this.uuid).then(res => {
            if (res.data.code == 200) {
              setToken(res.data.data.access_token);
                api.getInfo().then(res2 => {

                  console.log("222222222res2.data.user2222"+JSON.stringify(res2.data.user))

//{"id":9,"username":"18665380291","usertype":"00","sex":"0","status":"0","delflag":"0","addbycd":"sys","addtime":"2022-03-17 04:18:52","active":1,"customerid":7}
                  this.$store.dispatch('setUser',{
                    id:res2.data.user.id,
                    username:res2.data.user.username,
                    usertype:res2.data.user.usertype,
                    sex:res2.data.user.sex,
                    customerid:res2.data.user.customerid,
                    avatar:res2.data.user.avatar,
                    score:res2.data.user.score,
                    experience:res2.data.user.experience,
                  });

                  this.setCookie(this.username,this.pass,7);

                  console.log("3333333333333333333.data.user2222"+JSON.stringify(res2.data.user))
                  //window.location.reload();
                  this.$emit("close")
                });
            }else{
              alert("操作失败："+res.data.msg);
              this.getCode();
            }
          });
        }
      }



      //console.log("getUser返回："+getUser())
     // console.log("username："+JSON.parse(getUser()).username)

/*
      this.pass = "";
      this.yzm = "";
      this.username = "";
      this.code = "";*/

    },
    tables() {
      this.$emit("tables");
    },
    hide(e) {
      if (Array.from(e.target.classList).indexOf("modal-backdrop") !== -1) {
        this.$emit("close");
      }
    },
    close() {
      this.$emit("close");
    },
    //设置cookie
    setCookie(c_name,c_pwd,exdays) {
      var exdate=new Date();//获取时间
      exdate.setTime(exdate.getTime() + 24*60*60*1000*exdays);//保存的天数
      //字符串拼接cookie
      window.document.cookie="userName"+ "=" +c_name+";path=/;expires="+exdate.toGMTString();
      window.document.cookie="userPwd"+"="+c_pwd+";path=/;expires="+exdate.toGMTString();
    },

    //读取cookie
    getCookie:function () {
      if (document.cookie.length>0) {
        var arr=document.cookie.split('; ');//这里显示的格式需要切割一下自己可输出看下
        for(var i=0;i<arr.length;i++){
          var arr2=arr[i].split('=');//再次切割
          //判断查找相对应的值
          if(arr2[0]=='userName'){
           // this.ruleForm.userName=arr2[1];//保存到保存数据的地方
            this.username=arr2[1];
          }else if(arr2[0]=='userPwd'){
            //this.ruleForm.password=arr2[1];
            this.pass=arr2[1];
          }
        }
      }
    },
    //清除cookie
    clearCookie:function () {
      this.setCookie("","",-1);//修改2值都为空，天数为负1天就好了
    },
    tabyz() {
      if (!this.username) {
        this.error.user = "手机号不能为空";
        return;
      }
      if (this.timer) {
        return "";
      }
      let num = 60;
      api.sendPone(this.username);
      this.text = "重新发送(" + num-- + "秒)";
      this.timer = setInterval(() => {
        if (num == 0) {
          clearInterval(this.timer);
          this.text = "重新发送";
          return null;
        }
        this.text = "重新发送(" + num-- + "秒)";
      }, 1000);
    },
  },
};
</script>

<style>
.phoneVerityBox .reSend {
  position: absolute;
  right: 14px;
  top: 14px;
  color: #368afe;
  cursor: pointer;
}
.Rmodel {
  position: fixed;
  background: #fff;
  z-index: 100000;
  width: 384px;
  padding-top: 24px;
  padding-bottom: 16px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 12px 24px 0 rgba(28, 31, 33, 0.1);
  border-radius: 12px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.Rmodel.in {
  opacity: 1;
}

.Rmodel-header {
  position: relative;
  padding: 0 20px 24px 30px;
}

.Rmodel-header h1 {
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
  height: 40px;
  color: #787d82;
}

.Rmodel-header h1.black-title {
  color: #1c1f21;
}

.Rmodel-header h1 span {
  float: left;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.Rmodel-header h1 span:not(:first-child) {
  margin-left: 32px;
}

.Rmodel-header .active-title,
.Rmodel-header span:hover {
  color: #f20d0d;
}

.Rmodel-header .active-title:after,
.Rmodel-header span:hover:after {
  content: " ";
  width: 16px;
  height: 4px;
  line-height: 4px;
  background: #f20d0d;
  border-radius: 2px;
  display: block;
  margin: 0 auto;
}

.rl-close {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background: url(https://www.imooc.com/static/img/nlogin.png) no-repeat 0 0 !important;
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
}

.rl-close:hover {
  background-position: 0 -21px;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.Rmodel-body {
  padding: 0 32px;
}

.error-erea {
  font-size: 14px;
  color: #f01400;
  padding-bottom: 5px;
  height: 20px;
}

.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000000c9;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.rl-model-footer {
  padding: 20px 30px 4px;
}

.rl-model-signup .ipt-phone {
  padding-left: 77px !important;
}

.rlf-appendix {
  line-height: 1em;
}

.rlf-appendix .rlf-autoin,
.rlf-appendix .rlf-forget {
  color: #9199a1;
}

.rlf-appendix .rlf-forget:hover {
  color: #545c63;
}

.rlf-appendix .rlf-line {
  margin: 0 16px;
  background: rgba(28, 31, 33, 0.1);
  width: 1px;
  height: 14px;
}

.rlf-appendix .auto-cbx {
  margin-right: 10px;
  vertical-align: -1px;
}

.rlf-appendix .ipt-agreement {
  color: #37f;
}

.rlf-appendix .ipt-agreement:hover {
  color: #0f618a;
}

.Rmodel .ipt {
  width: 100%;
  height: 48px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px;
  box-shadow: 2px 0 5px 0 #fff;
  background: rgba(28, 31, 33, 0.06);
  border-radius: 8px;
  border: 0;
}

.ipt::-webkit-input-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt::-moz-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt:-ms-input-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt-email {
  background-position: 0 0;
}

.ipt-pwd {
  background-position: 0 -48px;
}

.ipt-nick {
  background-position: 0 -96px;
}

.ipt-phone {
  padding-left: 57px !important;
}

.rlf-areacode {
  position: absolute;
  cursor: pointer;
  left: 16px;
  top: 12px;
  font-size: 16px;
  color: #1c1f21;
  text-align: left;
  line-height: 24px;
}

.rlf-verify-group .rlf-input {
  background: #fff;
  padding-left: 7px;
  width: 120px;
  vertical-align: top;
}

.rlf-verify-group img {
  height: 38px;
  width: 70px;
  margin: 0 10px;
  vertical-align: top;
  display: inline-block;
}

.rlf-verify-group span {
  display: inline-block;
  color: #39b94e;
  cursor: pointer;
  height: 40px;
  vertical-align: top;
  line-height: 150%;
  margin-top: -4px;
}

.not-register-tips,
.rlf-tip-wrap {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}

.not-register-tips {
  display: none;
}

.rlf-g-tip {
  height: 20px;
}

.rlf-tip-globle {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}

.rlf-tip-error {
  color: #f01400;
}

.btn-full {
  width: 100%;
  box-sizing: border-box !important;
}

.btn-block {
  display: block;
  width: auto;
}

.forgetpwd-resultwrap {
  text-align: center;
  display: none;
  width: 260px;
}

.pop-login-signup-box {
  box-sizing: border-box;
  font-size: 16px;
  color: #f20d0d;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
}

.pop-login-signup-box span {
  cursor: pointer;
}

.pop-login-sns {
  text-align: center;
  font-size: 14px;
  margin-bottom: 24px;
}

#signup .pop-login-sns {
  margin-bottom: 13px;
}

.pop-login-sns .rlf-other {
  margin-left: 30px;
  margin-right: 24px;
  padding-right: 24px;
  display: inline-block;
  font-size: 14px;
  color: #f20d0d;
  text-align: left;
  line-height: 24px;
  border-right: 1px solid #1a1c1f21;
  cursor: pointer;
}

.pop-login-sns .rlf-other:hover {
  color: #c20a0a;
}

.pop-login-sns a {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.pop-login-sns a + a {
  margin-left: 64px;
}

.pop-login-sns i {
  font-size: 24px;
  line-height: 48px;
}

.pop-login-sns .pop-sns-weixin {
  position: relative;
  background-color: #e5ffe1;
  color: #00cc63;
}

.pop-login-sns .pop-sns-weixin:after {
  content: "推荐登录";
  position: absolute;
  top: -18px;
  left: 4px;
  width: 4em;
  padding: 6px 10px;
  font-size: 16px;
  background-color: #fff;
  border: 2px solid #d4ffe9;
  border-radius: 100px 100px 100px 0;
  transform: scale(0.5);
}

.pop-login-sns .pop-sns-qq {
  background-color: #edf5ff;
  color: #368afe;
}

.pop-login-sns .pop-sns-weibo {
  background-color: #fff2f5;
  color: #e5254d;
}

.privacy_tip {
  font-size: 14px;
  color: #9199a1;
  text-align: center;
}

.privacy_tip a {
  font-size: 14px;
  color: #37f;
}

.privacy_tip a:link,
.privacy_tip a:visited {
  font-size: 14px;
  color: #37f;
}

.Rmodel .ipt-verify {
  background-image: none;
}

.Rmodel .icon-refresh {
  position: absolute;
  right: 90px;
  top: 12px;
  color: #b5b9bc;
  font-size: 24px;
  display: inline-block;
  -webkit-transition: 0.4s linear;
  transition: 0.4s ease-in;
}

.Rmodel .icon-refresh:hover {
  color: #787d82;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
}

.Rmodel .form-control {
  position: relative;
}

.Rmodel .proclaim-loc {
  position: relative;
}

.Rmodel .proclaim-btn {
  font-size: 23px;
  color: #b5b9bc;
  position: absolute;
  top: 12px;
  right: 14px;
}

.Rmodel .proclaim-btn:hover {
  color: #717a84;
}

.Rmodel .verify-img {
  width: 80px;
  height: 32px;
}

.Rmodel .verify-extra {
  line-height: 20px;
}

.Rmodel .verify-extra a {
  color: #17823b;
}

.Rmodel .btn-red {
  background-color: #f01400;
  border-color: #f01400;
}

.Rmodel .btn-red:hover {
  background-color: #ff6464;
  border-color: #ff6464;
}

.Rmodel .ipt-error,
.Rmodel .rlf-tip-error {
  color: #f01400;
}

.Rmodel .rlf-tip-error {
  height: 30px;
  line-height: 30px;
}

.Rmodel .js-proclaim-on .js-txt-pwd {
  display: none;
}

.Rmodel .js-proclaim-off .js-pass-pwd {
  display: none !important;
}

.Rmodel input[type="text"]::-ms-clear {
  width: 0;
  height: 0;
}

.Rmodel input[type="password"]::-ms-reveal {
  width: 0;
  height: 0;
}

.verify-img-wrap {
  position: absolute;
  right: 2px;
  top: 8px;
  width: 84px;
  height: 32px;
}

.rlf-tip-wrap {
  clear: both;
}

.areacode-box {
  display: none;
  width: 320px;
  height: 280px;
  position: absolute;
  top: 132px;
  left: 32px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 8px;
}
</style>
