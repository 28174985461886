import Vue from 'vue'
import Vuex from 'vuex'
import Cookie from '../ulti/tools'
import ajax from '../ulti/ajax'
Vue.use(Vuex)
let Cookies = Cookie.Cookie;
export default new Vuex.Store({
  state: {
    cache:{
      islogin:false,
      user:{}
    }
  },
  mutations: {
    set_user(state,user){
      state.cache.islogin = true;
      state.cache.user = user;
    },
    lyaout_user(){
      state.cache.islogin = false;
      state.cache.user = {};
    },

    set_cartcount(state,cartcount){
      state.cache.cartcount = cartcount;
    },
    add_cartcount(state){
      if(state.cache.user.cartcount==undefined){
        state.cache.user.cartcount=0;
      }
      state.cache.user.cartcount =  state.cache.user.cartcount+1;
    },
  },
  gutters:{
    isUser:(state)=>{
      let user = Cookies.get('user');
      if(user){
        commit('set_user',JSON.parse(user))
      }else{
        return false;
      }
    }
  },
  actions: {
    setUser({commit},user){
      commit('set_user',user)
      Cookies.set('user',JSON.stringify(user))
    },

    lyaout({commit}){
      Cookies.set('user','')
      //commit('lyaout_user',{})
    },

    add_cartcount({commit}){
      commit('add_cartcount')
    },

  },
  modules: {
  }
})
