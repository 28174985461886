<template>
  <div class="modal-backdrop">
    <div id="signup" class="rl-modal rl-model-signup in" aria-hidden="false">
      <div class="rl-modal-header">
        <button type="button" class="rl-close" @click="hide"></button>
        <h1 class="black-title">快速注册</h1>
      </div>
      <div class="rl-modal-body js-modal-body js-registerWrap">
        <div class="captcha-border-box js-singnup-captcha">
          <div class="js-singnup-captcha-box captcha-verify-box"></div>
        </div>
        <form id="signup-form pr">
          <div class="rlf-group pr">
            <div class="rlf-areacode js-code-btn">+<span>86</span><i class="imv2-arrow1_d"></i></div>
            <input type="text" maxlength="37" value="" data-callback="checkusername" data-validate="require-mobile-phone" autocomplete="off" class="ipt ipt-phone js-zhuge-signup-phone" placeholder="请输入注册手机号" v-model="phone"/>
            <p class="rlf-tip-wrap errorHint color-red" data-error-hint="请输入正确的手机号"></p>
          </div>
          <p class="subtitle" style="display: none">短信验证码已发送至 <span class="js-phoneNumber"></span></p>
          <div class="rlf-group pr">
            <input type="text" data-minlength="4" class="ipt ipt-pwd js-zhuge-signup-message" placeholder="请输入短信验证码" maxlength="4" autocomplete="off" v-model="pass"/>
            <p class="reSend pa active js-signup-send-box"><span @click="getCon" class="js-signup-send">{{ text }}</span></p>
            <p class="rlf-tip-wrap errorHint color-red" data-error-hint="请输入正确验证码"></p>
          </div>

          <div class="rlf-group proclaim-loc  pr">
            <a href="javascript:void(0)" hidefocus="true" class="proclaim-btn js-proclaim imv2-visibility_off is-pwd" tabindex="-1"></a>
            <input type="text" data-validate="require-newpassword" class="ipt ipt-pwd js-pass-pwd js-zhuge-signup-password" placeholder="请输入密码" maxlength="20" v-model="passw" autocomplete="off"/>
            <p class="rlf-tip-wrap errorHint color-red"></p>
          </div>

          <!--<div class="rlf-group rlf-appendix form-control clearfix" style="margin-bottom: 0">
            <label for="signup-protocol" class="rlf-autoin l" hidefocus="true">
              <input type="checkbox" class="auto-cbx" id="signup-protocol"/>同意
              <a class="ipt-agreement" target="_blank" href="https://www.imooc.com/help/detail/89">《p8课堂注册协议》</a>&amp;
              <a href="https://moco.imooc.com/privacy.html" class="ipt-agreement" target="_blank">《隐私政策》</a>
            </label>
            <p class="rlf-tip-wrap errorHint color-red rlf-tip-globle" id="signup-globle-error" data-error-hint="请同意p8课堂注册协议"></p>
          </div>-->
          <div class="rlf-group clearfix">
            <a href="javascript:void(0)" id="signup-btn" hidefocus="true" class="moco-btn moco-btn-red moco-btn-lg btn-full btn r" @click="regit">立即注册</a>
          </div>
        </form>
      </div>
      <div class="rl-model-footer">
        <div class="pop-login-signup-box clearfix">
          <span @click="tables" data-fromto="signup:signin" class="xa-showSignin">返回账号登录</span>
        </div>
       <!-- <div class="pop-login-sns clearfix">
          <a href="javascript:void(0)" hidefocus="true" data-login-sns="/passport/user/tplogin?tp=weixin" class="pop-sns-weixin" data-source="立即注册"><i class="imv2-weixin11"></i></a>
          <a href="javascript:void(0)" hidefocus="true" data-login-sns="/passport/user/tplogin?tp=qq" class="pop-sns-qq"><i class="imv2-qq11"></i></a>
          <a href="javascript:void(0)" class="pop-sns-weibo"><i class="imv2-weibo11"></i></a>
        </div>-->
      </div>
    </div>
  </div>
</template>
<script>
import api from "../../ulti/ajax";
export default {
  data() {
    return {
      text: "获取验证码",
      phone: "",
      pass: "",
      passw:""
    };
  },

  async mounted() {
    setTimeout(() => {
      this.phone='';this.pass='';this.passw='';
    }, 1000);
    setTimeout(() => {
      this.phone='';this.pass='';this.passw='';
    }, 1000);
    setTimeout(() => {
      this.phone='';this.pass='';this.passw='';
    }, 3000);

  },

  methods: {
    regit(){

      if(this.phone==undefined || this.phone=='undefined' || this.phone=='' ){
        alert("请输入正确的手机号");
        return;
      }
      if(this.pass==undefined || this.pass=='undefined' || this.pass=='' ){
        alert("请输入正确的短信验证码");
        return;
      }
      if(this.passw==undefined || this.passw=='undefined' || this.passw=='' ){
        alert("请输入登录密码");
        return;
      }
      api.regit(this.phone,this.pass,this.passw).then(res => {
        console.log(JSON.stringify(res))
        if (res.code == 200) {
          this.$emit("tables");
          alert("注册成功,请登陆");
          //this.tables();
        }else{
          alert("操作失败："+res.data.msg);
        }
      });
    },


    hide(e) {
      this.$emit("close");
    },
    tables() {
      this.$emit("tables");
    },
    getCon(){
      if(this.phone==undefined || this.phone=='undefined' || this.phone=='' ){
        alert("请输入正常的手机号");
        return;
      }
      if(this.timer){
        return ''
      }
      if(this.phone!=undefined  && this.phone!='undefined' && this.phone.length==11){
        api.sendPone(this.phone)
        let num = 60;
        this.timer = setInterval(()=>{
          if(num == 0){
            clearInterval(this.timer);
            this.text = '重新发送'
            return null;
          }
          this.text = "重新发送("+(num--)+"秒)"
        },1000)
      }else{

        //提示错误信息


      }


      // 请求验证码
    }
  },
};
</script>

<style>
.modal-backdrop {
  background-color: #fff;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 0.75;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1040;
  background-color: #000;
}
.modal-backdrop {
  z-index: 999999;
}
.modal-backdrop,
.modal-backdrop.fade.in {
  opacity: 1;
  background: #000000ad;
}

.fade {
  opacity: 0;
  -webkit-transition: opacity 0.15s linear;
  -moz-transition: opacity 0.15s linear;
  -o-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}

.rl-model-footer {
  padding: 20px 30px 4px;
}

.rl-model-signup .ipt-phone {
  padding-left: 77px !important;
}

.rlf-appendix {
  line-height: 1em;
}

.rlf-appendix .rlf-autoin,
.rlf-appendix .rlf-forget {
  color: #9199a1;
}

.rlf-appendix .rlf-forget:hover {
  color: #545c63;
}

.rlf-appendix .rlf-line {
  margin: 0 16px;
  background: rgba(28, 31, 33, 0.1);
  width: 1px;
  height: 14px;
}

.rlf-appendix .auto-cbx {
  margin-right: 10px;
  vertical-align: -1px;
}

.rlf-appendix .ipt-agreement {
  color: #37f;
}

.rlf-appendix .ipt-agreement:hover {
  color: #0f618a;
}

.rl-modal .ipt {
  width: 100%;
  height: 48px;
  line-height: 20px;
  box-sizing: border-box;
  padding: 14px;
  box-shadow: 2px 0 5px 0 #fff;
  background: rgba(28, 31, 33, 0.06);
  border-radius: 8px;
  border: 0;
}

.ipt::-webkit-input-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt::-moz-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt:-ms-input-placeholder {
  color: #9199a1;
  font-size: 16px;
}

.ipt-email {
  background-position: 0 0;
}

.ipt-pwd {
  background-position: 0 -48px;
}

.ipt-nick {
  background-position: 0 -96px;
}

.ipt-phone {
  padding-left: 57px !important;
}

.rlf-areacode {
  position: absolute;
  cursor: pointer;
  left: 16px;
  top: 12px;
  font-size: 16px;
  color: #1c1f21;
  text-align: left;
  line-height: 24px;
}

.rlf-verify-group .rlf-input {
  background: #fff;
  padding-left: 7px;
  width: 120px;
  vertical-align: top;
}

.rlf-verify-group img {
  height: 38px;
  width: 70px;
  margin: 0 10px;
  vertical-align: top;
  display: inline-block;
}

.rlf-verify-group span {
  display: inline-block;
  color: #39b94e;
  cursor: pointer;
  height: 40px;
  vertical-align: top;
  line-height: 150%;
  margin-top: -4px;
}

.not-register-tips,
.rlf-tip-wrap {
  font-size: 12px;
  height: 20px;
  line-height: 20px;
}

.not-register-tips {
  display: none;
}

.rlf-g-tip {
  height: 20px;
}

.rlf-tip-globle {
  font-size: 14px;
  height: 20px;
  line-height: 20px;
}

.rlf-tip-error {
  color: #f01400;
}

.btn-full {
  width: 100%;
  box-sizing: border-box !important;
}

.btn-block {
  display: block;
  width: auto;
}

.forgetpwd-resultwrap {
  text-align: center;
  display: none;
  width: 260px;
}

.pop-login-signup-box {
  box-sizing: border-box;
  font-size: 16px;
  color: #f20d0d;
  text-align: center;
  line-height: 24px;
  margin-bottom: 24px;
}

.pop-login-signup-box span {
  cursor: pointer;
}

.pop-login-sns {
  text-align: center;
  font-size: 14px;
  margin-bottom: 24px;
}

#signup .pop-login-sns {
  margin-bottom: 13px;
}

.pop-login-sns .rlf-other {
  margin-left: 30px;
  margin-right: 24px;
  padding-right: 24px;
  display: inline-block;
  font-size: 14px;
  color: #f20d0d;
  text-align: left;
  line-height: 24px;
  border-right: 1px solid #1a1c1f21;
  cursor: pointer;
}

.pop-login-sns .rlf-other:hover {
  color: #c20a0a;
}

.pop-login-sns a {
  display: inline-block;
  width: 48px;
  height: 48px;
  border-radius: 100px;
}

.pop-login-sns a + a {
  margin-left: 64px;
}

.pop-login-sns i {
  font-size: 24px;
  line-height: 48px;
}

.pop-login-sns .pop-sns-weixin {
  position: relative;
  background-color: #e5ffe1;
  color: #00cc63;
}

.pop-login-sns .pop-sns-weixin:after {
  content: "推荐登录";
  position: absolute;
  top: -18px;
  left: 4px;
  width: 4em;
  padding: 6px 10px;
  font-size: 16px;
  background-color: #fff;
  border: 2px solid #d4ffe9;
  border-radius: 100px 100px 100px 0;
  transform: scale(0.5);
}

.pop-login-sns .pop-sns-qq {
  background-color: #edf5ff;
  color: #368afe;
}

.pop-login-sns .pop-sns-weibo {
  background-color: #fff2f5;
  color: #e5254d;
}

.privacy_tip {
  font-size: 14px;
  color: #9199a1;
  text-align: center;
}

.privacy_tip a {
  font-size: 14px;
  color: #37f;
}

.privacy_tip a:link,
.privacy_tip a:visited {
  font-size: 14px;
  color: #37f;
}

.rl-modal .ipt-verify {
  background-image: none;
}

.rl-modal .icon-refresh {
  position: absolute;
  right: 90px;
  top: 12px;
  color: #b5b9bc;
  font-size: 24px;
  display: inline-block;
  -webkit-transition: 0.4s linear;
  transition: 0.4s ease-in;
}

.rl-modal .icon-refresh:hover {
  color: #787d82;
  transform: rotate(360deg);
  -webkit-transform: rotate(360deg);
  -moz-transform: rotate(360deg);
  -o-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
}

.rl-modal .form-control {
  position: relative;
}

.rl-modal .proclaim-loc {
  position: relative;
}

.rl-modal .proclaim-btn {
  font-size: 23px;
  color: #b5b9bc;
  position: absolute;
  top: 12px;
  right: 14px;
}

.rl-modal .proclaim-btn:hover {
  color: #717a84;
}

.rl-modal .verify-img {
  width: 80px;
  height: 32px;
}

.rl-modal .verify-extra {
  line-height: 20px;
}

.rl-modal .verify-extra a {
  color: #17823b;
}

.rl-modal .btn-red {
  background-color: #f01400;
  border-color: #f01400;
}

.rl-modal .btn-red:hover {
  background-color: #ff6464;
  border-color: #ff6464;
}

.rl-modal .ipt-error,
.rl-modal .rlf-tip-error {
  color: #f01400;
}

.rl-modal .rlf-tip-error {
  height: 30px;
  line-height: 30px;
}

.rl-modal .js-proclaim-on .js-txt-pwd {
  display: none;
}

.rl-modal .js-proclaim-off .js-pass-pwd {
  display: none !important;
}

.rl-modal input[type="text"]::-ms-clear {
  width: 0;
  height: 0;
}

.rl-modal input[type="password"]::-ms-reveal {
  width: 0;
  height: 0;
}

.verify-img-wrap {
  position: absolute;
  right: 2px;
  top: 8px;
  width: 84px;
  height: 32px;
}

.rlf-tip-wrap {
  clear: both;
}

.areacode-box {
  display: none;
  width: 320px;
  height: 280px;
  position: absolute;
  top: 132px;
  left: 32px;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 8px;
}

.areacode-box .code-tab {
  padding: 16px;
}

.areacode-box .code-tab li {
  display: inline-block;
  margin-right: 9px;
  cursor: pointer;
}

.areacode-box .code-tab li.curr {
  font-weight: 700;
  color: #f20d0d;
}

.areacode-box .code-title {
  border-top: 1px solid #e1e2e2;
  margin: 0 16px 14px;
}

.areacode-box .code-title span {
  margin-top: -10px;
  position: relative;
  font-size: 12px;
  color: #9199a1;
  background: #fff;
  float: left;
  width: 36px;
}

.areacode-box .code-list {
  overflow: hidden;
  height: 190px;
}

.areacode-box .code-list li {
  padding-left: 16px;
  font-size: 16px;
  color: #545c63;
  text-align: left;
  line-height: 48px;
  cursor: pointer;
}

.areacode-box .code-list li:hover {
  background: rgba(56, 61, 66, 0.06);
}

.areacode-box .code-list .nano-pane {
  right: 4px;
}

.areacode-box .code-list .nano-slider {
  background: rgba(28, 31, 33, 0.1) !important;
  border-radius: 4px !important;
}

.autoul {
  width: 298px;
  padding: 0;
  border-top: 0 none;
  position: absolute;
  height: 156px;
  overflow: hidden;
  background: #fff;
  box-shadow: 0 8px 16px 0 rgba(28, 31, 33, 0.2);
  border-radius: 8px;
  z-index: 10000;
}

.autoul .autoli {
  color: #787d82;
  height: 39px;
  line-height: 39px;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  padding: 0 10px;
  overflow: hidden;
  display: none;
  text-overflow: ellipsis;
}

.autoul .lihover {
  background-color: #edf1f2;
}

.autoul .showli {
  display: block;
}

.form-control .autoul {
  width: 318px;
}

.erweima,
.pcLogin {
  background: url(/static/module/common/img/erweima.png) no-repeat 0 0;
  width: 60px;
  height: 60px;
  position: absolute;
  bottom: 1px;
  right: 0;
  cursor: pointer;
  border-radius: 0 0 12px 0;
}

.pcLogin {
  background: url(/static/module/common/img/pcLogin.png) no-repeat 0 0;
}

.qrcode {
  margin: 10px 80px 31px;
  position: relative;
}

.qrcode_title {
  font-size: 16px;
  color: #717a84;
  text-align: center;
}

.qrcode_mark {
  color: #717a84;
  font-size: 12px;
  text-align: center;
}

.qrcode-inner {
  position: absolute;
  background-color: #fff;
  height: 160px;
  width: 160px;
  opacity: 0.95;
}

.qrcode_state_scaned {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
  font-weight: 700;
  color: #1c1f21;
}

.qrcode_state_scaned i {
  margin-top: 44px;
  display: block;
  font-size: 48px;
  color: #37f;
}

.qrcode_state_validate {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  text-align: center;
}

.qrcode_state_validate span {
  padding-top: 44px;
  color: #1c1f21;
  padding-bottom: 12px;
  display: block;
  font-weight: 700;
}

.qrcode_state_validate a {
  background: #383d42;
  color: #fff !important;
}

.phoneVerityBox {
  font-family: "微软雅黑";
}

.backNotify,
.phoneVerityBox .subtitle,
.phoneVerityBox .title {
  text-align: center;
}

.phoneVerityBox .title {
  margin-bottom: 4px;
  font-size: 16px;
  color: #1c1f21;
  text-align: center;
  line-height: 24px;
  font-family: "微软雅黑";
}

.phoneVerityBox .subtitle {
  font-size: 14px;
  color: #545c63;
  line-height: 22px;
  margin-bottom: 20px;
  font-family: "微软雅黑";
}

.phoneVerityBox .subtitle span {
  color: #1c1f21;
}

.phoneVerityBox .reSend {
  top: 12px;
  right: 14px;
  font-size: 14px;
  color: #9199a1;
  line-height: 24px;
}

.phoneVerityBox .reSend.active {
  right: 14px;
  color: #37f !important;
  cursor: pointer;
}

.phoneVerityBox .reSend.active:hover {
  color: #19a1e6 !important;
}

.phoneVerityBox .voicecode-send {
  margin-left: 8px;
  padding-left: 8px;
  font-size: 14px;
  color: #37f;
  line-height: 24px;
  cursor: pointer;
  border-left: 1px solid #d3d6d9;
}

.phoneVerityBox .voicecode-send:hover {
  color: #19a1e6;
}

.phoneVerityBox .voicesend i {
  display: inline-block;
  margin-right: 4px;
}

#signup .subtitle,
#signup .title,
.backNotify {
  text-align: center;
}

#signup .title {
  margin-bottom: 4px;
  font-size: 16px;
  color: #1c1f21;
  text-align: center;
  line-height: 24px;
  font-family: "微软雅黑";
}

#signup .subtitle {
  font-size: 14px;
  color: #545c63;
  line-height: 22px;
  margin-bottom: 20px;
  font-family: "微软雅黑";
}

#signup .subtitle span {
  color: #1c1f21;
}

#signup .reSend {
  top: 12px;
  right: 14px;
  font-size: 14px;
  color: #9199a1;
  line-height: 24px;
}

#signup .reSend.active {
  right: 14px;
  color: #37f !important;
  cursor: pointer;
}

#signup .reSend.active .js-reSend:hover,
#signup .reSend.active .js-signup-send:hover,
#signup .reSend.active .voicecode-send:hover {
  color: #19a1e6 !important;
}

#signup .voicecode-send {
  margin-left: 8px;
  padding-left: 8px;
  font-size: 14px;
  color: #37f;
  line-height: 24px;
  cursor: pointer;
  border-left: 1px solid #d3d6d9;
}

#signup .voicesend i {
  display: inline-block;
  margin-right: 4px;
}

.rl-modal-body .backNotify {
  color: #777c81;
  margin-top: 15px;
  cursor: pointer;
}

.rl-modal-body .user_header {
  width: 76px;
  background: url(/static/module/common/img/default_user.png) no-repeat 0 0;
  height: 76px;
  margin: auto;
  margin-top: 40px;
}

.rl-modal-body .btn-finished {
  width: 160px;
  height: 50px;
}

.rl-modal-body .finished_wrap {
  text-align: center;
}

.rl-modal-body .active {
  color: #13181e !important;
  cursor: pointer;
}

.rl-modal-body .active:hover {
  color: #ff6464 !important;
}

.reg-success {
  padding-top: 38px;
  text-align: center;
}

.reg-success .icon-send-success {
  width: 96px;
  height: 96px;
  line-height: 96px;
  border-radius: 50%;
  background: rgba(28, 31, 33, 0.1);
  display: inline-block;
  font-size: 64px;
  color: #1c1f21;
}

.reg-success p {
  font-size: 16px;
  color: #1c1f21;
  text-align: center;
  line-height: 24px;
  font-weight: 700;
  padding: 16px 0 5px;
}

.reg-success .get-info {
  font-size: 12px;
  color: #545c63;
  text-align: center;
  line-height: 18px;
}

.reg-success .moco-btn-lg {
  margin-top: 36px;
  margin-bottom: 20px;
}

.nano {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.nano > .nano-content {
  position: absolute;
  overflow: scroll;
  overflow-x: hidden;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.nano > .nano-content:focus {
  outline: thin dotted;
}

.nano > .nano-content::-webkit-scrollbar {
  display: none;
}

.has-scrollbar > .nano-content::-webkit-scrollbar {
  display: block;
}

.nano > .nano-pane {
  position: absolute;
  width: 10px;
  right: 10px;
  top: 0;
  bottom: 0;
  -webkit-transition: 0.2s;
  -moz-transition: 0.2s;
  -o-transition: 0.2s;
  transition: 0.2s;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
}

.nano > .nano-pane > .nano-slider {
  background: #4e5a5e;
  position: relative;
  margin: 0 1px;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
}

.nano-pane.active,
.nano-pane.flashed,
.nano:hover > .nano-pane {
  opacity: 0.99;
}

#signup .rl-modal-body {
  position: relative;
}

#signup .rl-modal-body .captcha-border-box {
  display: none;
  width: 320px;
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  margin-top: -104px;
  z-index: 1;
}

#signup .rl-modal-body .captcha-border-box:after {
  content: "";
  display: block;
  position: fixed;
  top: -1px;
  bottom: -1px;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.75);
  border-radius: 12px;
  z-index: 1;
  box-shadow: 0 0 30px 0 #060606;
}

#signup .rl-modal-body .captcha-border-box .captcha-verify-box {
  position: relative;
  z-index: 2;
  border: 8px solid #fff;
  background-color: #fff;
  border-radius: 8px;
}

.btn {
  display: inline-block;
  margin-bottom: 0;
  font-weight: 200;
  text-align: center;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  text-decoration: none;
  box-sizing: content-box;
  background-image: none;
  border: 1px solid transparent;
  -webkit-appearance: none;
  white-space: nowrap;
  outline: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.btn.focus,
.btn:focus,
.btn:hover {
  color: #fff;
  text-decoration: none;
}

.btn.active,
.btn:active {
  outline: 0;
  background-image: none;
}

.btn.disabled,
.btn[disabled],
fieldset[disabled] .btn {
  pointer-events: none;
  opacity: 0.65;
  box-shadow: none;
}

.btn-red {
  color: #fff;
  background-color: #f20d0d;
  border-color: #f20d0d;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  -weibkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 138px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
}

.btn-red:link,
.btn-red:visited {
  color: #fff;
}

.btn-red.focus,
.btn-red:focus,
.btn-red:hover {
  color: #fff;
  background-color: #f42f2f;
  border-color: #f42f2f;
}

.btn-red.active,
.btn-red:active {
  background-color: #da0b0b;
  border-color: #da0b0b;
}

.btn-red.disabled,
.btn-red.disabled.active,
.btn-red.disabled.focus,
.btn-red.disabled:active,
.btn-red.disabled:focus,
.btn-red.disabled:hover,
.btn-red[disabled],
.btn-red[disabled].active,
.btn-red[disabled].focus,
.btn-red[disabled]:active,
.btn-red[disabled]:focus,
.btn-red[disabled]:hover {
  cursor: default;
  box-shadow: none;
  background-color: #f20d0d;
  border-color: #f20d0d;
}

.btn-blue {
  color: #fff;
  background-color: #1481b8;
  border-color: #1481b8;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  -weibkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 138px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
}

.btn-blue:link,
.btn-blue:visited {
  color: #fff;
}

.btn-blue.focus,
.btn-blue:focus,
.btn-blue:hover {
  color: #fff;
  background-color: #1898d8;
  border-color: #1898d8;
}

.btn-blue.active,
.btn-blue:active {
  background-color: #1271a1;
  border-color: #1271a1;
}

.btn-blue.disabled,
.btn-blue.disabled.active,
.btn-blue.disabled.focus,
.btn-blue.disabled:active,
.btn-blue.disabled:focus,
.btn-blue.disabled:hover,
.btn-blue[disabled],
.btn-blue[disabled].active,
.btn-blue[disabled].focus,
.btn-blue[disabled]:active,
.btn-blue[disabled]:focus,
.btn-blue[disabled]:hover {
  cursor: default;
  box-shadow: none;
  background-color: #1481b8;
  border-color: #1481b8;
}

.btn-green {
  color: #fff;
  background-color: #17823b;
  border-color: #17823b;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  -weibkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 138px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
}

.btn-green:link,
.btn-green:visited {
  color: #fff;
}

.btn-green.focus,
.btn-green:focus,
.btn-green:hover {
  color: #fff;
  background-color: #1ca048;
  border-color: #1ca048;
}

.btn-green.active,
.btn-green:active {
  background-color: #136c31;
  border-color: #136c31;
}

.btn-green.disabled,
.btn-green.disabled.active,
.btn-green.disabled.focus,
.btn-green.disabled:active,
.btn-green.disabled:focus,
.btn-green.disabled:hover,
.btn-green[disabled],
.btn-green[disabled].active,
.btn-green[disabled].focus,
.btn-green[disabled]:active,
.btn-green[disabled]:focus,
.btn-green[disabled]:hover {
  cursor: default;
  box-shadow: none;
  background-color: #17823b;
  border-color: #17823b;
}

.btn-normal {
  color: #787d82;
  background-color: #fff;
  border-color: #d0d6d9;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  -weibkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  transition: all 0.3s;
  width: 138px;
  height: 38px;
  line-height: 38px;
  font-size: 14px;
}

.btn-normal:link,
.btn-normal:visited {
  color: #787d82;
}

.btn-normal.focus,
.btn-normal:focus,
.btn-normal:hover {
  color: #787d82;
  background-color: #edf1f2;
  border-color: #98a1a6;
}

.btn-normal.active,
.btn-normal:active {
  background-color: #f2f2f2;
  border-color: #c2cace;
}

.btn-normal.disabled,
.btn-normal.disabled.active,
.btn-normal.disabled.focus,
.btn-normal.disabled:active,
.btn-normal.disabled:focus,
.btn-normal.disabled:hover,
.btn-normal[disabled],
.btn-normal[disabled].active,
.btn-normal[disabled].focus,
.btn-normal[disabled]:active,
.btn-normal[disabled]:focus,
.btn-normal[disabled]:hover {
  cursor: default;
  box-shadow: none;
  background-color: #fff;
  border-color: #d0d6d9;
}

.btn-sm {
  width: 78px;
  height: 28px;
  line-height: 28px;
  font-size: 12px;
}

.id_icon_imoocs {
  background-image: url(/static/module/common/img/id_icon/imoocs.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.id_icon_lecturer {
  background-image: url(/static/module/common/img/id_icon/lecturer.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.id_icon_writer {
  background-image: url(/static/module/common/img/id_icon/writer.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.id_icon_teaching_design {
  background-image: url(/static/module/common/img/id_icon/teaching_design.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.id_icon_assistant {
  background-image: url(/static/module/common/img/id_icon/assistant.svg);
  background-size: contain;
  background-repeat: no-repeat;
}

.pad9 {
  padding: 0 9px;
}

.w298 {
  width: 278px;
  padding: 0 9px;
}

.w200 {
  width: 180px;
  padding: 0 9px;
}

.statement {
  margin-bottom: 12px;
}

#improve-mode {
  width: 430px;
  box-sizing: border-box;
}

.moco-modal-layer #improve-mode {
  display: block !important;
}

.improve .wfbd {
  position: absolute;
  left: 330px;
  bottom: 48px;
  font-size: 12px;
  color: #727679;
}

.improve .wfbd:hover {
  text-decoration: underline;
}

.improve label {
  display: inline-block;
  width: 104px;
  font-weight: 700;
  color: #07111b;
  text-align: right;
}

.improve .help-inline {
  left: 120px;
  width: 298px;
  position: absolute;
  line-height: 24px;
  bottom: -24px;
  font-size: 12px;
  color: #f01414;
}

.improve .help-inline label.error {
  color: inherit;
  text-align: left;
  font-weight: 400;
  width: auto;
}

.improve .help-inline i {
  display: inline-block;
  color: #fff;
  width: 20px;
  background: #00a0dc;
  border-radius: 2px;
  cursor: pointer;
  font-style: normal;
  text-align: center;
}

.improve .box {
  line-height: 36px;
  position: relative;
  margin-bottom: 8px;
}

.improve .box input[type="password"],
.improve .box input[type="text"] {
  box-sizing: border-box;
  padding: 0 12px;
  height: 36px;
  border: 1px solid rgba(84, 92, 99, 0.6);
  border-radius: 6px;
  margin-left: 16px;
}

.improve .box .getCode {
  position: absolute;
  padding: 0 9px;
  top: 8px;
  right: 12px;
  font-size: 12px;
  color: #9199a1;
  line-height: 20px;
}

.improve .box .getCode.active {
  right: 85px;
  color: #1481b8;
  cursor: pointer;
}

.improve .box .getCode.active:hover {
  color: #19a1e6;
}

.improve .box .voicecode-send {
  position: absolute;
  right: 12px;
  top: 8px;
  padding-left: 8px;
  font-size: 12px;
  color: #1481b8;
  line-height: 20px;
  cursor: pointer;
  border-left: 1px solid #d3d6d9;
}

.improve .box .voicecode-send i {
  display: inline-block;
  margin-right: 4px;
}

.improve .box .voicecode-send:hover {
  color: #19a1e6;
}

.improve .box .l {
  position: relative;
}

.improve .box .l .icon-pw {
  position: absolute;
  top: 0;
  right: 0;
  color: #b7bbbf;
  line-height: 38px;
  font-size: 24px;
  margin-right: 7px;
}
/*
.improve .box .l .icon-pw:hover {
    color: #71777d
}

.improve .box .cancelBtn,.improve .box .submitBtn {
    display: inline-block;
    text-align: center
}

.improve .box .submitBtn {
    margin-left: 120px;
    vertical-align: top
}

.improve .box .cancelBtn {
    margin-left: 12px
}

.improve .edit {
    margin-bottom: 24px
}

.improve .edit p.w298 {
    margin: 10px 0 30px 111px;
    font-size: 12px;
    color: #93999f;
    line-height: 22px
} */

.improve .info-val {
  margin-left: 16px;
  color: #07111b;
}

.rl-modal-title h2 {
  padding-top: 22px;
  font-size: 16px;
  color: #1c1f21;
  line-height: 24px;
  text-align: center;
  font-weight: 700;
}

.rl-modal-title p {
  padding: 8px 0 20px;
  font-size: 14px;
  color: #545c63;
  letter-spacing: 0;
  text-align: center;
  line-height: 22px;
}
@charset "UTF-8";
.word-break {
  word-break: break-all;
  word-wrap: break-word;
}

.keep-word {
  white-space: nowrap;
  word-break: keep-all;
}

.display_flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.display_flex > * {
  display: block;
}

.display_inline-flex {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: -webkit-inline-flex;
  display: inline-flex;
}

.display_inline-flex > * {
  display: block;
}

.flex-direction_column {
  -webkit-box-orient: vertical;
  -ms-flex-direction: column;
  -webkit-flex-direction: column;
  flex-direction: column;
}

.justify-content_flex-center {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
}

.justify-content_flex-end {
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
}

.justify-content_flex-justify {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  -webkit-justify-content: space-between;
  justify-content: space-between;
}

.align-items_flex-start {
  -webkit-box-align: start;
  -ms-flex-align: start;
  -webkit-align-items: flex-start;
  align-items: flex-start;
}

.align-items_flex-end {
  -webkit-box-align: end;
  -ms-flex-align: end;
  -webkit-align-items: flex-end;
  align-items: flex-end;
}

.align-items_center {
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.align-items_flex-justify {
  -webkit-box-align: justify;
  -ms-flex-align: justify;
  -webkit-align-items: space-between;
  align-items: space-between;
}

.align-items_baseline {
  -webkit-box-align: baseline;
  -ms-flex-align: baseline;
  -webkit-align-items: baseline;
  align-items: baseline;
}

.flex_auto {
  -webkit-box-flex: 1;
  -ms-flex: auto;
  -webkit-flex: auto;
  flex: auto;
}

.flex_1 {
  width: 0;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  -webkit-flex: 1;
  flex: 1;
}

.order_2 {
  -webkit-box-ordinal-group: 2;
  -ms-flex-order: 2;
  -webkit-order: 2;
  order: 2;
}

.order_3 {
  -webkit-box-ordinal-group: 3;
  -ms-flex-order: 3;
  -webkit-order: 3;
  order: 3;
}

blockquote,
body,
button,
code,
dd,
div,
dl,
dt,
fieldset,
form,
h1,
h2,
h3,
h4,
h5,
h6,
input,
legend,
li,
ol,
p,
pre,
td,
textarea,
th,
ul {
  margin: 0;
  padding: 0;
}

fieldset,
img {
  border: 0;
}

:focus {
  outline: 0;
}

address,
caption,
cite,
code,
dfn,
optgroup,
th,
var {
  font-style: normal;
  font-weight: 400;
}

strong {
  font-weight: 400;
}

em {
  font-style: normal;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: 400;
  font-family: "Microsoft YaHei", PingFangSC-Regular;
}

abbr,
acronym {
  border: 0;
  font-variant: normal;
}

code,
kbd,
samp,
tt {
  font-size: 100%;
}

button,
input,
select,
textarea {
  border: none;
}

body {
  background: #fff;
  color: #5e5e5e;
  font: 14px/2em Microsoft YaHei, SimSun, Arial;
}

ol,
ul {
  list-style: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

caption,
th {
  text-align: left;
}

sub,
sup {
  font-size: 100%;
  vertical-align: baseline;
}

:link,
:visited,
ins {
  text-decoration: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: "";
  content: none;
}

a:link,
a:visited {
  color: #5e5e5e;
}

a:hover {
  color: #c9394a;
}

a:active {
  color: #666;
}

.clearfix:after {
  content: "\0020";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.l {
  float: left;
}

.r {
  float: right;
}

.clear {
  height: 0;
  overflow: hidden;
  clear: both;
}

.hide {
  display: none;
}

.btn.hide {
  display: none;
}

a.hidefocus {
  outline: 0;
}

button.hidefocus::-moz-focus-inner {
  border: none;
}

a:focus {
  outline: 0;
  -moz-outline: none;
}

input,
textarea {
  outline: 0;
}

.overhidden {
  overflow: hidden;
}

.rl-modal {
  position: fixed;
  background: #fff;
  z-index: 100000;
  width: 384px;
  padding-top: 24px;
  padding-bottom: 16px;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-shadow: 0 12px 24px 0 rgba(28, 31, 33, 0.1);
  border-radius: 12px;
  transition: all 0.2s;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
}

.rl-modal.in {
  opacity: 1;
}

.rl-modal-header {
  position: relative;
  padding: 0 20px 24px 30px;
}

.rl-modal-header h1 {
  font-size: 18px;
  line-height: 40px;
  font-weight: 700;
  height: 40px;
  color: #787d82;
}

.rl-modal-header h1.black-title {
  color: #1c1f21;
}

.rl-modal-header h1 span {
  float: left;
  text-align: center;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}

.rl-modal-header h1 span:not(:first-child) {
  margin-left: 32px;
}

.rl-modal-header .active-title,
.rl-modal-header span:hover {
  color: #f20d0d;
}

.rl-modal-header .active-title:after,
.rl-modal-header span:hover:after {
  content: " ";
  width: 16px;
  height: 4px;
  line-height: 4px;
  background: #f20d0d;
  border-radius: 2px;
  display: block;
  margin: 0 auto;
}

.rl-close {
  position: absolute;
  top: 11px;
  right: 20px;
  width: 17px;
  height: 17px;
  cursor: pointer;
  background: url(/static/module/common/img/nlogin.png) no-repeat 0 0;
  transition: transform 0.2s;
  -webkit-transition: transform 0.2s;
  -moz-transition: transform 0.2s;
  -o-transition: transform 0.2s;
}

.rl-close:hover {
  background-position: 0 -21px;
  transform: scale(1.1);
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
}

.rl-modal-body {
  padding: 0 32px;
}

.error-erea {
  font-size: 14px;
  color: #f01400;
  padding-bottom: 5px;
  height: 20px;
}
</style>
