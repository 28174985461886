<template>

  <div id="app">
    <headers/>
    <router-view/>
   <!-- <fiex/>-->
    <foolter/>
  </div>
</template>
<script>
  import header from  './components/header/nav.vue'
/*  import fiex from  './components/smallCompotent/fiex.vue'*/
  import foolter from  './components/foolter/index.vue'
  import api from './ulti/ajax'
  export default {
    components:{
      headers:header,
    //  fiex,
      foolter
    },
    async created(){

    }
  }
</script>

<style>
  @import url('./assets/css/base.css');
  @import url('./assets/css/icon.css');
</style>
