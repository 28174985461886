<template>
    <div class="home">
        <div id="main">
            <div class="bgfff banner-box">
                <div @mouseleave="closetList($event)" class="g-banner pr">
                    <div class="submenu a hide" v-for="(v, i) in navlist" :key="i" :data-id="'p' + v.id" style="display: none">
                        <div class="inner-box">
                            <h2 class="type">{{ v.name }}</h2>
                            <div class="tag clearfix"></div>
                            <div class="lore">
                                <span class="title">知识点：</span>
                                <p class="lores clearfix"><a :key="i" v-for="(coursetype, i) in v.coursetypesubs"  :href="`#/list/?c=${coursetype.name}`">{{ coursetype.name }}</a></p>
                            </div>
                        </div>
                        <div class="recomment clearfix">
                            <a :href="`#/learn?id=${course.id}`"  title="" class="recomment-item" v-for="(course, i) in v.courses" :key="i">
                                <div class="img" style="background-size: 100% 100%" :style="{ backgroundImage: `url(${course.imagesmall})` }"></div>
                                <div class="details">
                                    <!--路径单独写-->
                                    <div class="title-box"><p class="title"><span class="text">{{ course.name }}</span><span class="tag tixi">{{ course.typelable }}</span></p></div>
                                    <div class="bottom">
                                        <span class="discount-name">优惠价</span>
                                        <span class="price">￥{{ course.price }}</span> ·
                                        <span class="difficulty"> {{ course.levellable }} </span> ·
                                        <span class="num"><i class="imv2-set-sns"></i>{{ course.learnpeple }}</span>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                    <div class="menuContent">
                        <div @mouseover="opens(v.id, $event)" class="item js-" v-for="(v, i) in navlist" :key="i" :data-id="v.id">
                            <span class="title">{{ v.name }}: </span><span class="sub-title">{{ v.title }}</span><i class="imv2-arrow1_r"></i>
                        </div>
                    </div>
                    <div class="g-banner-content">
                        <div class="swiper mySwiper">
                            <div class="swiper-wrapper">
                               <div v-for="(v, i) in banners" :key="i" class="swiper-slide">
                                    <img :src="v.image" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="bg000">
                <div class="container-types new-course">
                    <h3 class="types-title justify-content_flex-start">
                        <ul class="menu">
                            <li v-for="(i, v) in goodCoursetype" @click="tabActive(i)" :key="v" :class="{ curr: xshkActve == i }"><a href="javascript:;">{{ i }}</a></li>
                        </ul>
                    </h3>
                    <div class="list clearfix show">
                        <item :v="v" v-for="(v, i) in goodCourses" :key="i"/>
                    </div>
                </div>
            </div>
            <div class="bgfff">
                <div class="container-types spell-group" data-group="秒杀">
                    <h3 class="types-title justify-content_flex-start">
                        <ul class="menu">
                            <li class="curr" data-type="10:00"><a href="javascript:;">10:00</a></li>
                            <li class="" data-type="12:00"><a href="javascript:;">12:00</a></li>
                            <li class="" data-type="14:00"><a href="javascript:;">14:00</a></li>
                            <li class="" data-type="15:00"><a href="javascript:;">16:00</a></li>
                        </ul>
                    </h3>

                    <div class="list clearfix show">
                        <item :v="v" v-for="(v, i) in goodCourses" :key="i"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import api from "../ulti/ajax";
    import Swiper from "swiper";
    import "swiper/dist/css/swiper.min.css";

    import item from '../components/smallCompotent/item.vue'
    import 'element-ui/lib/theme-chalk/index.css'

    export default {
        name: "Home",
        data() {
            return {
                dialogVisible: true,
                navlist: [],
                banners: [],
                allData: [],
                // 新上好课分类列表
                goodCoursetype: ['推荐', '前端开发'],
                // 新上好课选中
                xshkActve: "推荐",
                goodCourses: [],
                // 数据分类
                fenlei: {}
            };
        },

        async created() {
            // 获取banner 列表数据
            this.navlist = (await api.getIndexList()).data.rows;
            this.banners = (await api.getBannerList()).data.rows;
            // this.xshk = (await api.getCourseList()).data.rows;
            //console.log(this.banners)
            //  console.log(this.navlist)
            this.allData = (await api.getIndexAllData()).data.rows;
            //  处理分类数据 以 coursetypeid 属性 进行分类
            this.goodCourses = this.allData;

            document.title='p8课堂';


            /*this.allData.map(e => {
                if (this.fenlei[e.type]) {
                    this.fenlei[e.type].push(e)
                } else {
                    this.fenlei[e.type] = [e]
                }
            })*/
            //  将分类为1 的 数据 取10个 放置在新上好课的模块中
            // this.xshk = this.fenlei['1'].splice(0,8)

            var swiper = new Swiper(".mySwiper", {
                 pagination: ".swiper-pagination", //分页器
                 nextButton: ".swiper-button-next", //前进按钮
                 prevButton: ".swiper-button-prev", //后退按钮
                loop: true, //循环
                speed: 1000,
                paginationClickable: true, //分页器点击
                spaceBetween: 30, // swiper-slide 间的距离为30
                autoplay:3000,
                autoplayDisableOnInteraction: false, //点击不会取消自动
            });
        },

       mounted() {
        },
        methods: {
            tabActive(i) {
                this.xshkActve = i;
            },
            closetList(e) {
                document.querySelectorAll(".submenu").forEach((e) => {
                    e.style.display = "none";
                });
            },
            // 打开菜单
            opens(v, e) {
                document.querySelectorAll(".submenu").forEach((e) => {
                    e.style.display = "none";
                });
                let dom = document.querySelector('[data-id="p' + v + '"]');
                dom.style.display = "block";
            },
        },
        components: {
            item
        },
    };
</script>
<style scoped>
    @import url("../assets/css/home.css");
</style>
