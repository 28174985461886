<template>
<div id="footer">
<div class="waper">
    <div class="footerwaper clearfix">
       <!-- <div class="followus r">
            <a class="followus-weixin" href="javascript:;" title="微信"><span class="imv2-weixin11"></span><div class="flw-weixin-box"></div></a>
            <a class="followus-weibo" rel="nofollow" href="http://weibo.com/u/3306361973" target="_blank" title="新浪微博"><span class="imv2-weibo11"></span></a>
            <a class="followus-qzone" rel="nofollow" href="http://user.qzone.qq.com/1059809142/" target="_blank" title="QQ空间"><span class="imv2-qq11"></span></a>
        </div>-->
        <div class="footer_intro l">
            <div class="footer_link">
                <ul>
                    <li><a href="/" target="_blank">网站首页</a></li>
                    <!--<li><a href="/contact" target="_blank">关于我们</a></li>-->
                    <li> <a href="#/contact?id=lxwm" target="_blank">联系我们</a></li>
                   <!--  <li> <a href="/help" target="_blank">帮助中心</a></li>-->
                     <!--<li> <a href="/feedback" target="_blank">意见反馈</a></li>-->
                </ul>
            </div>
            <!--<p>Copyright <img draggable="false" class="moco-emoji" alt="©" src="https://www.imooc.com/static/moco/v1.0/images/face/36x36/a9.png"> 2022 imooc.com All Rights Reserved | <a rel="nofollow" href="https://beian.miit.gov.cn/" style="color: #787D82;" target="_blank">京ICP备 12003892号-11</a> <a rel="nofollow" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11010802030151" style="margin:0 5px;color: #787D82;" target="_blank"><i class="beian"></i>京公网安备11010802030151号</a></p>
        -->

        </div>
    </div>
    </div>
</div>
</template>

<script>
export default {

}
</script>

<style scoped>

#footer {
    background: #fff;
    border-top: 1px solid #f3f5f6;
    font-size: 12px;
    color: #93999F;
    padding: 36px 0;
    min-width: 620px;
    position: relative;
    z-index: 21;
    font-weight: 300
}

#footer .waper {
    width: auto;
    max-width: 1152px;
    margin: 0 auto
}

#footer .footer_intro .footer_link {
    margin-bottom: 8px;
    line-height: 1.8
}

#footer .footer_intro .footer_link ul {
    overflow: hidden;
    margin-top: -1px
}

#footer .footer_intro .footer_link li {
    margin-right: 27px;
    text-align: center;
    float: left;
    font-size: 14px
}

#footer .footer_intro .footer_link a:link,#footer .footer_intro .footer_link a:visited,#footer .footer_intro .footer_link a:active {
    color: #545C63
}

#footer .footer_intro .footer_link a:hover {
    color: #1c1f21
}

#footer p {
    line-height: 1.7
}

#footer .beian {
    background: url(//www.imooc.com/static/img/beianicon.png) no-repeat 0 0;
    width: 16px;
    height: 16px;
    vertical-align: middle;
    background-size: cover;
    display: inline-block;
    margin-right: 4px;
    margin-top: -4px
}

#footer .footer_logo {
    display: none;
    float: left;
    background: url(//www.imooc.com/static/img/common/footer-sprite.png) 0 -230px no-repeat;
    height: 40px;
    width: 120px;
    margin: 0 20px
}

#footer .des {
    width: 445px
}

#footer .followus {
    padding-left: 30px
}

#footer .followus a {
    float: left;
    position: relative;
    width: 32px;
    height: 32px;
    margin-right: 24px;
    text-align: center;
    background-image: none;
    background: #B7BBBF;
    border-radius: 100px;
    z-index: 2
}

#footer .followus a:hover {
    background: #545C63
}

#footer .followus a:last-child {
    margin-right: 0
}

#footer .followus a [class^=imv2] {
    color: #fff;
    font-size: 20px;
    line-height: 30px
}

#footer .followus .followus-weixin {
    background-position: 0 -279px
}

#footer .followus .followus-weixin:hover .followus-weixin {
    background-position: 0 -279px
}

#footer .followus .followus-weixin:hover .flw-weixin-box {
    display: block
}

#footer .followus .flw-weixin-box {
    position: absolute;
    display: none;
    width: 160px;
    height: 200px;
    left: -62px;
    bottom: 40px;
    background: url(//www.imooc.com/static/img/common/elevator2.png) no-repeat;
    background-size: 100%;
    background-position: center;
    box-shadow: 0 -8px 16px 0 rgba(7,17,27,0.2);
    border-radius: 8px
}

#footer .followus .followus-weibo {
    background-position: 0 -321px
}

#footer .followus .followus-qzone {
    background-position: 0 -363px
}

#footer .followus .followus-favorites {
    text-align: center;
    background-image: none;
    background: #545C63;
    border-radius: 100px;
    opacity: 1
}

#footer .followus .followus-favorites:hover {
    background: #F01414
}

#footer .followus .followus-favorites .imv2-star1 {
    color: #fff;
    font-size: 20px;
    line-height: 30px
}

#footer .followus .followus-favorites .favorites-tips {
    display: none;
    position: absolute;
    bottom: 50px;
    left: 50%;
    width: 240px;
    padding: 12px 20px;
    margin-left: -140px;
    color: #545C63;
    background-color: #fff;
    box-shadow: 0 -8px 16px 0 rgba(7,17,27,0.2);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    transition: all .2s;
    font-size: 12px;
    line-height: 1
}

#footer .followus .followus-favorites .favorites-tips span.imv2-star-revert {
    font-size: 14px;
    color: #545C63;
    line-height: 1
}

#footer .followus .followus-favorites .favorites-tips b {
    font-size: 16px;
    color: #F01414
}

#footer .followus .followus-favorites:hover .favorites-tips {
    display: block
}

</style>
