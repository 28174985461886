import Cookies from 'js-cookie'

const TokenKey = 'Admin-Token'
const UserKey = 'UserKey'
const CarKey = 'CarKey'

const ExpiresInKey = 'Admin-Expires-In'
const tokenvalue = 'eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2lkIjoxLCJ1c2VyX2tleSI6ImNhNzg0MmNhLTU2MmMtNDYyOC05MTNlLWM0YWVmYjE2OTlmNiIsInVzZXJuYW1lIjoiYWRtaW4ifQ.migEa91swVDoyWNExqp_L5ByRW4GoLF8lE29n5KnUN8A1EjGhFaVGCg95el0bzB8OQdHpue4YeU0MlV1OXeNuA';

export function getToken() {
  return  Cookies.get(TokenKey);
}
export function setToken(token,user) {
  return Cookies.set(TokenKey, token)
}


export function removeToken() {
  return Cookies.remove(TokenKey)
}

export function getExpiresIn() {
  return Cookies.get(ExpiresInKey) || -1
}

export function setExpiresIn(time) {
  return Cookies.set(ExpiresInKey, time)
}

export function removeExpiresIn() {
  return Cookies.remove(ExpiresInKey)
}
